import React from 'react';
import styled from 'styled-components';
import { Item, DateLabel, Label } from 'fx-ui';

export type VariantType = 'incoming' | 'outgoing';
interface ChatMessageProps {
  isOutgoing: boolean;
  sender: string;
  dateSent: Date;
}

const StyledMessageContainer = styled.div<{ isOutgoing: boolean }>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-self: ${({ isOutgoing }) => (isOutgoing ? 'flex-end' : 'flex-start')};
  background: ${({ theme, isOutgoing }) =>
    isOutgoing ? theme.palette.primary : theme.palette.silver};
  border-radius: 5px;
  margin: 0px 20px 11px;
  max-width: 75%;

  &:first-of-type {
    margin-top: 15px;
  }

  .chat-message-text {
    color: ${({ theme }) => theme.messageTextColor};
    font-family: ${({ theme }) => theme.baseFontFamily};
    font-size: 13px;
    font-weight: 500;
    padding: 5px 10px 0px;
  }

  .chat-message-details-item {
    padding: 2px 10px 4px;

    .chat-message-sender-label {
      color: ${({ theme }) => theme.palette.gray};
      font-size: 11px;
      margin-right: 10px;
    }

    .chat-message-date-label {
      color: ${({ theme }) => theme.palette.gray};
      font-family: ${({ theme }) => theme.baseFontFamily};
      font-size: 11px;
      text-transform: uppercase;
    }
  }
`;

const ChatMessage: React.FC<ChatMessageProps> = ({
  children,
  isOutgoing,
  sender,
  dateSent,
}) => {
  return (
    <StyledMessageContainer isOutgoing={isOutgoing}>
      <div className="chat-message-text">{children}</div>
      <Item className="chat-message-details-item">
        <Label className="chat-message-sender-label">{sender}</Label>
        <DateLabel
          className="chat-message-date-label"
          date={dateSent}
          format="time"
        />
      </Item>
    </StyledMessageContainer>
  );
};

export default ChatMessage;
