import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { DateLabel } from 'fx-ui';
import { Container } from '../Container';
import { Label } from '../Label';

interface ChatNotificationProps extends HTMLAttributes<HTMLDivElement> {
  date: Date;
  action: string;
}

const StyledContainer = styled(Container)`
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
  margin: 19px 0 30px;

  .chat-notification-date-header {
    color: ${({ theme }) => theme.palette.white};
    font-family: ${({ theme }) => theme.baseFontFamily};
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
  }

  .chat-notification-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: ${({ theme }) => theme.palette.gray};
    padding: 15px 20px;
    min-width: 100%;

    .chat-notification-text {
      color: ${({ theme }) => theme.palette.silver};
      font-size: 13px;
      font-weight: 500;
      margin-right: 10px;
    }

    .chat-notification-time {
      color: ${({ theme }) => theme.palette.silver};
      font-size: 11px;
    }
  }
`;

function formatAction(action: string) {
  switch (action) {
    case 'updateTender':
      return 'Updated the Freight Request';

    case 'cancelTender': {
      return 'Cancelled the Freight Request';
    }
    case 'reopenTender': {
      return 'Reopened the Freight Request';
    }
    case 'submitBid': {
      return 'Submitted an Offer';
    }
    case 'submitBidOnBehalf': {
      return 'Submitted an Offer on behalf';
    }
    case 'updateBid': {
      return 'Updated the Offer';
    }
    case 'withdrawBid': {
      return 'Withdrew the Offer';
    }
    case 'unwithdrawBid': {
      return 'Resubmitted Withdrawn Offer';
    }
    case 'suspendBid': {
      return 'Suspended the Offer';
    }
    case 'unsuspendBid': {
      return 'Unsuspended the Offer';
    }
    case 'indicationOffers': {
      return 'Set the Freight Request to Indication Offers';
    }
    case 'negotiationRequest': {
      return ' Requested a Negotiation';
    }
    case 'negotiationAccept': {
      return 'Accepted a Negotiation Request';
    }
    case 'negotiationGoOnSubs':
    case 'negotiationTookBidOnSubs': {
      return 'Entered Subs';
    }
    case 'negotiationLiftSubs': {
      return 'Lifted Subs';
    }
    case 'negotiationCancelRequest': {
      return 'Cancelled a Negotiation Request';
    }
    case 'negotiationReject': {
      return 'Rejected a Negotiation Request';
    }
    case 'negotiationFailSubs': {
      return 'Failed Subs';
    }
    case 'submitCounter': {
      return 'submitted a counter';
    }
    case 'goOnSubs': {
      return 'Request to go on subs';
    }
    default:
      return '(missing information)';
  }
}

const ChatNotification: React.FC<ChatNotificationProps> = ({
  action,
  date,
}) => {
  return (
    <StyledContainer>
      <div className="chat-notification-container">
        <Label className="chat-notification-text">{formatAction(action)}</Label>
        <DateLabel
          className="chat-notification-time"
          date={date}
          format="time"
        />
      </div>
    </StyledContainer>
  );
};

export default ChatNotification;
