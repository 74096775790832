import gql from 'graphql-tag';

export const LIST_MASTER_SHIPS = gql`
  query($shipName: String!) {
    masterShips: listMasterShips(shipName: $shipName) {
      id
      name
    }
  }
`;
