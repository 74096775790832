import gql from 'graphql-tag';

export const GET_USER = gql`
  query {
    currentUser: getUser {
      username
      id
    }
  }
`;
