import React from 'react';
import { Card, CardContent, Title, Label } from 'fx-ui';
import { List } from 'fx-ui/components/List';
import styled from 'styled-components';

interface FreightRequestDetailsConditionsProps {
  conditions: string[];
}

const StyledCard = styled(Card)`
  .sub-title {
    margin-top: 10px;
  }
  .footer-label {
  }
`;

const FreightRequestDetailsConditions: React.FC<
  FreightRequestDetailsConditionsProps
> = ({ conditions }) => {
  return (
    <StyledCard>
      <CardContent>
        <Title subtitle>Conditions</Title>
        <Title className="sub-title" subtitle>
          Instructions to owners
        </Title>
        <List items={conditions}></List>
        <Label className="footer-label" small italic>
          Please note that FreiXchange will charge the successful owner or
          owners representative a commission of 0.25% of the invoiced freight
          and demurrage
        </Label>
      </CardContent>
    </StyledCard>
  );
};

export default FreightRequestDetailsConditions;
