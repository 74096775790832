import React from 'react';
import styled from 'styled-components';
import { Container } from '../Container';
import { Icon } from '../Icon';

interface DocumentProps {
  documentName: string;
  documentUrl?: string;
}

const StyledContainer = styled(Container)`
  margin: 8px 0;

  .icon-link svg {
    width: 24px;
    height: 24px;
    max-height: 24px;
  }

  .document-link {
    color: ${({ theme }) => theme.palette.primary};
    font-family: ${({ theme }) => theme.baseFontFamily};
    font-size: 15px;
    align-self: center;
    cursor: pointer;
    text-decoration: underline;
    margin-left: 6px;
  }
`;

const Document: React.FC<DocumentProps> = ({ documentName, documentUrl }) => {
  return (
    <StyledContainer>
      <Icon className="icon-link" name="attachment"></Icon>
      <a className="document-link" href={documentUrl}>
        {documentName}
      </a>
    </StyledContainer>
  );
};

export default Document;
