import React from 'react';
import styled from 'styled-components';
import { Icon } from '../Icon';
import { Label } from '../Label';

interface CardActionProps {
  icon: string;
  slot?: 'start' | 'end';
  badge?: number;
  onClick?: (obj: any) => void;
}

interface StyledCardActionProps {
  slot?: 'start' | 'end';
}

const StyledCardAction = styled.div<StyledCardActionProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: ${({ slot = 'end' }) => (slot === 'end' ? 'inherit' : 'auto')};
  margin-left: ${({ slot = 'end' }) => (slot === 'end' ? 'auto' : 'inherit')};
  width: 50%;
  height: 44px;
  color: ${({ theme }) => theme.palette.primary};
  border-bottom-right-radius: ${({ slot }) =>
    slot === 'end' ? '6px' : 'none'};
  border-bottom-left-radius: ${({ slot }) =>
    slot === 'start' ? '6px' : 'none'};
  &:hover {
    background: ${({ theme }) => theme.palette.gray};
  }

  .card-action-icon {
    margin-right: 5px;
  }
  .action-label {
    font-weight: 700;
  }
`;

const CardAction: React.FC<CardActionProps> = ({
  children,
  icon,
  badge,
  ...props
}) => {
  return (
    <StyledCardAction {...props}>
      <Icon
        className="card-action-icon"
        name={icon}
        fill="primary"
        badge={badge}
      />
      <Label className="action-label" color="primary" small>
        {children}
      </Label>
    </StyledCardAction>
  );
};

export default CardAction;
