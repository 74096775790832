import React, { FC } from 'react';
import { Title, Input, Button } from 'fx-ui';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { RouteComponentProps, Link, navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { useRoot } from 'app/core/root/root';

interface RegisterPageProps {
  email?: string | null;
}

const StyledContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .second-time-login-header {
    margin-bottom: 40px;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-bottom: 30px;
  }

  .switch-account {
    margin-top: 27px;
    margin-bottom: 35px;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.palette.medium};
  }

  button {
    margin-bottom: 30px;
  }

  .login {
    margin-bottom: 30px;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.palette.medium};
  }
`;

const ForgotPasswordPage: FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const { requestPasswordReset } = useRoot();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: ({ email }) => {
      requestPasswordReset(email);
      navigate('/');
    },
  });
  return (
    <StyledContainer onSubmit={formik.handleSubmit}>
      <div className="second-time-login-header">
        <Title subtitle>reset password</Title>
      </div>
      <div className="input-wrapper">
        <Title subtitle>your email</Title>
        <Input
          name="email"
          placeholder="your email"
          type="email"
          onChange={formik.handleChange}
          value={formik.values.email}
        />
      </div>
      <Button type="submit" size="large">
        reset via email
      </Button>
      <Link className="login" to="/">
        <Title subtitle>{t('login')}</Title>
      </Link>
    </StyledContainer>
  );
};

export default ForgotPasswordPage;
