import React from 'react';
import styled from 'styled-components';

export interface LabelProps {
  color?: string;
  small?: boolean;
  italic?: boolean;
  centered?: boolean;
  className?: string;
}

export interface StyledLabelProps {
  color?: string;
  small?: boolean;
  italic?: boolean;
  centered?: boolean;
}

const StyledLabel = styled.label<StyledLabelProps>`
  display: flex;
  flex-flow: row wrap;
  justify-content: ${({ centered = false }) => (centered ? 'center' : 'left')};
  font-family: ${({ theme }) => theme.baseFontFamily};
  font-style: ${({ italic = false }) => (italic ? 'italic' : 'none')};
  font-size: ${({ small = false }) => (small ? '14px' : '16px')};
  font-weight: 400;
  color: ${({ theme, color }) =>
    color ? theme.palette[color] : theme.textColor};
`;

const Label: React.FC<LabelProps> = ({ children, ...props }) => {
  return <StyledLabel {...props}>{children}</StyledLabel>;
};

export default Label;
