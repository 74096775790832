import gql from 'graphql-tag';

export const SEND_MESSAGE = gql`
  mutation sendMessage($freightRequestId: ID!, $message: String!) {
    sendFixtureLogMessage(
      freightRequestId: $freightRequestId
      message: $message
    )
  }
`;
