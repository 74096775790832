import React, { FC } from 'react';
import { Router } from '@reach/router';
import styled from 'styled-components';

import { AuthContainer } from '../../auth';

import {
  OverviewContainer,
  DetailsContainer,
  IndicateOfferContainer,
} from 'app/freight-request';
import { useRoot } from '../root/root';

const StyledRouter = styled(Router)`
  height: 100%;
  width: 100%;
`;

const CoreLayout: FC = () => {
  const { authenticated } = useRoot();

  return authenticated ? (
    <StyledRouter>
      <OverviewContainer path="/" />
      <DetailsContainer path="/:freightRequestId" />
      <IndicateOfferContainer path="/:freightRequestId/indicate" />
    </StyledRouter>
  ) : (
    <AuthContainer />
  );
};

export default CoreLayout;
