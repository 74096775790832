import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';
import OverviewPage from '../components/overview/overview.page';
import { FreightRequestInterface } from '../models/freight-request.models';
import {
  LIST_ALL_FREIGHT_REQUESTS,
  FREIGHT_REQUEST_UPDATED_SUB,
} from '../graphql';

export interface FreightRequestListResponseData {
  freightRequests: FreightRequestInterface[];
  freightRequestUpdated: FreightRequestInterface;
}

const OverviewContainer: React.FC<RouteComponentProps> = () => {
  const {
    subscribeToMore,
    loading,
    error,
    data,
    refetch: refreshFreightRequests,
    networkStatus,
  } = useQuery<FreightRequestListResponseData>(LIST_ALL_FREIGHT_REQUESTS, {
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    throw new Error(JSON.stringify(error));
  }
  const { freightRequests } = data || { freightRequests: [] };
  console.log({ networkStatus });

  return (
    <OverviewPage
      freightRequests={freightRequests}
      loading={loading || networkStatus === 4}
      refreshFreightRequests={() => refreshFreightRequests()}
      subscribeToChanges={() =>
        subscribeToMore({
          document: FREIGHT_REQUEST_UPDATED_SUB,
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev;

            const changedItem = subscriptionData.data.freightRequestUpdated;

            return Object.assign({}, prev, {
              freightRequests: prev.freightRequests.map(fr => {
                if (fr.freightRequestId === changedItem.freightRequestId) {
                  return changedItem;
                }

                return fr;
              }),
            });
          },
        })
      }
    />
  );
};

export default OverviewContainer;
