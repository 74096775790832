import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { FreightRequestStatus } from 'app/freight-request/models/freight-request.models';

export interface StatusProps extends HTMLAttributes<HTMLLabelElement> {
  status: FreightRequestStatus;
}

const getStatusColor = (theme: any, status: FreightRequestStatus) => {
  const {
    SUBMITTED,
    REJECTED,
    UNREAD,
    DRAFTING,
    OUTDATED,
    FIRM,
    COMPLETED,
  } = FreightRequestStatus;

  switch (status) {
    case UNREAD: {
      return theme.palette.success;
    }
    case DRAFTING: {
      return theme.colors.silverChalice;
    }
    case SUBMITTED: {
      return theme.colors.white;
    }
    case OUTDATED: {
      return theme.palette.warning;
    }
    case COMPLETED: {
      return theme.colors.silverChalice;
    }
    case REJECTED: {
      return theme.palette.danger;
    }
    case FIRM: {
      return theme.colors.white;
    }

    default:
      return theme.colors.white;
  }
};

const getStatusLabel = (status: FreightRequestStatus) => {
  const {
    SUBMITTED,
    REJECTED,
    UNREAD,
    DRAFTING,
    OUTDATED,
    FIRM,
    COMPLETED,
  } = FreightRequestStatus;

  switch (status) {
    case UNREAD: {
      return 'new cargo';
    }
    case DRAFTING: {
      return 'drafting';
    }
    case SUBMITTED: {
      return 'submitted';
    }
    case OUTDATED: {
      return 'resubmit';
    }
    case COMPLETED: {
      return 'closed';
    }
    case REJECTED: {
      return 'declined';
    }
    case FIRM: {
      return 'firm offer';
    }

    default:
      return '';
  }
};

const StyledStatus = styled.label<StatusProps>`
  background-color: transparent;
  color: ${({ theme, status }) => getStatusColor(theme, status)};
  font-size: 12px;
  font-family: ${({ theme }) => theme.baseFontFamily};
  font-weight: 700;
  text-transform: uppercase;
  line-height: 19px;
`;

const Status: React.FC<StatusProps> = ({ status }) => {
  const label = getStatusLabel(status);

  return <StyledStatus status={status}> {label} </StyledStatus>;
};

export default Status;
