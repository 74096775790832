const { GRAPHQL_SERVER_URL, GRAPHQL_WEBSOCKET_URL } = process.env;

export const environment = {
  production: false,
  graphql: {
    uri:
      GRAPHQL_SERVER_URL ||
      'https://fx-trading-server.prod.k8s.aws.freixchange.com',
    wsUri:
      GRAPHQL_WEBSOCKET_URL ||
      'wss://fx-trading-server.prod.k8s.aws.freixchange.com/graphql',
    // uri: GRAPHQL_SERVER_URL || 'http://localhost:4000',
    // wsUri: GRAPHQL_WEBSOCKET_URL || 'ws://localhost:4000/graphql',
    secret: '',
  },
};
