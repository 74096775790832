import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

interface SelectUnitProps {
  offerUnit: string;
  updateUnit: (event: ChangeEvent<HTMLInputElement>) => void;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.baseBorderColor};
  padding: 0.5em;

  .offer-unit {
  }
`;

const SelectUnit: React.FC<SelectUnitProps> = ({ offerUnit }) => {
  if (!offerUnit) {
    return null;
  }

  return (
    <StyledContainer>
      <div className="offer-unit">{offerUnit}</div>
    </StyledContainer>
  );
};

export default SelectUnit;
