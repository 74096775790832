import gql from 'graphql-tag';

export const FREIGHT_REQUEST_UPDATED_SUB = gql`
  subscription {
    freightRequestUpdated {
      freightRequestId
      details {
        layDate
        cancelling
      }
      company {
        companyName
      }
      cargo {
        cargoName
        quantity {
          unit
          amount
          tolerance
        }
      }
      routes {
        loadLocations {
          locationName
        }
        dischargeLocations {
          locationName
        }
        offer {
          offerAmount
          offerUnit
        }
      }
      ship {
        name
      }
      discussion {
        value
        created
        user {
          userId
          firstName
          lastName
        }
        type
      }
      unreadMessages
      status
      modified
      created
    }
  }
`;
