import React from 'react';
import { Title, Label } from 'fx-ui';
import styled from 'styled-components';
import { Cargo } from '../../models/freight-request.models';

interface OverviewHeaderProps {
  companyName: string;
  cargo: Cargo;
}

const StyledDiv = styled.div`
  margin: 15px 0;
`;

const StyledTitle = styled(Title)`
  font-size: 18px;
  font-weight: 700;
`;

const StyledLabel = styled(Label)`
  font-size: 16px;
  font-weight: 400;
`;

const OverviewHeader: React.FC<OverviewHeaderProps> = ({
  companyName,
  cargo: {
    cargoName,
    quantity: { amount, unit },
  },
}) => {
  return (
    <StyledDiv>
      <StyledTitle>{companyName}</StyledTitle>
      <StyledLabel>
        {amount} {unit.toUpperCase()} {cargoName}
      </StyledLabel>
    </StyledDiv>
  );
};

export default OverviewHeader;
