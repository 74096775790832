import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Icon } from '../Icon';
import { Container } from '../Container';

interface ToolbarProps {
  backAction?: () => {} | void;
  menuAction?: () => {} | void;
  closeAction?: () => {} | void;
  overviewAction?: () => {} | void;
  refreshAction?: () => {} | void;
  refreshLoading?: boolean;
  className?: string;
}

const rotateAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const StyledToolbar = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 65px;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.palette.silver};
  font-family: ${({ theme }) => theme.baseFontFamily};
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  z-index: 10;
  padding: 0 20px;
  box-sizing: border-box;
`;

const StyledContainerStart = styled(Container)`
  justify-content: center;
  .back-icon svg {
    width: 32px;
    height: 32px;
    max-height: 32px;
  }
`;

const StyledContainerEnd = styled(Container)`
  justify-content: center;
  .hamburger-icon {
    svg {
      width: 32px;
      height: 32px;
      max-height: 32px;
    }
  }
  .tile-icon,
  .refresh-icon {
    margin-right: 15px;
    transition: transform 0.5s ease;
    &.rotate {
      animation: ${rotateAnimation} 2s linear infinite;
    }
  }

  @media screen and (min-width: 768px) {
    .tile-icon,
    .hamburger-icon {
      display: none;
    }
  }
`;

const Toolbar: React.FC<ToolbarProps> = ({
  children,
  backAction,
  menuAction,
  closeAction,
  overviewAction,
  refreshAction,
  refreshLoading,
  className,
  ...props
}) => {
  return (
    <StyledToolbar
      className={[`fx-ui-toolbar`, className].join(' ')}
      {...props}>
      <StyledContainerStart>
        {backAction && (
          <Icon
            className="back-icon"
            name="caret"
            fill="white"
            rotate={90}
            onClick={backAction}></Icon>
        )}
        {!backAction &&
          (menuAction || closeAction || overviewAction) &&
          children}
      </StyledContainerStart>
      {((!backAction && !(menuAction || closeAction || overviewAction)) ||
        (backAction && (menuAction || closeAction || overviewAction))) &&
        children}
      <StyledContainerEnd>
        {refreshAction && (
          <Icon
            name="refresh"
            fill="white"
            onClick={refreshAction}
            className={`refresh-icon${refreshLoading ? ' rotate' : ''}`}
          />
        )}
        {overviewAction && (
          <Icon
            name="tiles"
            fill="white"
            onClick={overviewAction}
            className="tile-icon"
          />
        )}
        {(menuAction || closeAction) && (
          <Icon
            className="hamburger-icon"
            name={menuAction ? 'menu' : 'close'}
            fill="white"
            onClick={menuAction || closeAction}
          />
        )}
        {!(menuAction || closeAction || overviewAction) &&
          backAction &&
          children}
      </StyledContainerEnd>
    </StyledToolbar>
  );
};

export default Toolbar;
