import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import queryString from 'query-string';
import { useQuery } from '@apollo/react-hooks';
import {
  FreightRequestInterface,
  FreightRequestStatus,
} from '../models/freight-request.models';
import FreightRequestDetailsPage from '../components/details/details.page';
import Loading from 'app/shared/Loading/Loading';
import { useTranslation } from 'react-i18next';
import ErrorContainer from 'app/shared/Error/ErrorContainer';
import { GET_SINGLE_FREIGHT_REQUEST } from '../graphql';
import { useFreightRequest } from '../hooks/useFreightRequest';
import { SUBSCRIBE_SINGLE_FREIGHT_REQUEST } from '../graphql/subscribeFreightRequest.graphql';

interface Data {
  freightRequest: FreightRequestInterface;
  freightRequestUpdated?: FreightRequestInterface;
}
interface Variables {
  freightRequestId: string;
}

interface RejectionInputVariables {
  freightRequestId: string;
  reason: string;
  comment: string;
  status: string;
}

interface DetailsContainerProps extends RouteComponentProps {
  freightRequestId: string;
}

const DetailsContainer: React.FC<
  RouteComponentProps<DetailsContainerProps>
> = ({ freightRequestId = '', location: routerLocation = window.location }) => {
  const { t } = useTranslation();
  const { updateFreightRequestStatus } = useFreightRequest(freightRequestId);
  const { loading, error, data, subscribeToMore } = useQuery<Data, Variables>(
    GET_SINGLE_FREIGHT_REQUEST,
    { variables: { freightRequestId } }
  );

  useEffect(() => {
    subscribeToMore({
      document: SUBSCRIBE_SINGLE_FREIGHT_REQUEST,
      variables: { freightRequestId },
      updateQuery: (prev, { subscriptionData }) => {
        if (
          !(
            subscriptionData.data && subscriptionData.data.freightRequestUpdated
          )
        ) {
          return prev;
        }

        const { freightRequestUpdated } = subscriptionData.data;

        return {
          ...prev,
          ...freightRequestUpdated,
        };
      },
    });
  }, [subscribeToMore, freightRequestId]);

  const { openFixtureLog = false } = queryString.parse(routerLocation.search, {
    parseBooleans: true,
  });

  // const client = useApolloClient();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <ErrorContainer
        title="FR no longer available"
        message={JSON.stringify(error, undefined, 2)}
      />
    );
    // client.writeData({
    //   data: {
    //     errorMessage: {
    //       title: `No freight request with id ${freightRequestId}`,
    //       message: JSON.stringify(error),
    //     },
    //   },
    // });
    // navigate('/');
    // return null;
  }

  if (!data) {
    return (
      <ErrorContainer
        title={t('Missing Freight Request')}
        message={`${t(
          'Could not find freight request with ID'
        )} ${freightRequestId}`}
      />
    );
  }

  const { freightRequest } = data;
  const { REJECTED } = FreightRequestStatus;

  return (
    <FreightRequestDetailsPage
      freightRequest={freightRequest}
      openFixtureLog={openFixtureLog as boolean}
      rejectFreightRequest={(statusReason: string, statusComment: string) =>
        updateFreightRequestStatus({
          freightRequestId,
          status: REJECTED,
          statusReason,
          statusComment,
        })
      }
    />
  );
};

export default DetailsContainer;
