import React, { createContext, useContext, useState, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import * as theme from 'fx-ui/theme';
import Accounts from 'config/apollo';
import { CoreLayout } from '../layout';
import { GET_USER } from '../graphql/getUser.graphql';
import './root.css';

export interface CurrentUser {
  username: string;
  userId: string;
}

interface UserData {
  currentUser: {
    username: string;
    id: string;
  };
}

interface LoginDetails {
  email: string;
  password: string;
}

interface RootState {
  toggleTheme: () => void;
  fetchUser: () => void;
  login: (details: LoginDetails) => void;
  logout: () => void;
  requestPasswordReset: (email: string) => void;
  resetPassword: (token: string, newPassword: string) => void;
  currentUser: CurrentUser;
  authenticated: boolean;
  mode: 'light' | 'dark';
  error: string;
}

const StyledCoreLayout = styled(CoreLayout)`
  background-color: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  height: 100%;
`;

const initUser: CurrentUser = {
  username: '',
  userId: '',
};

const RootContext = createContext<RootState>({
  toggleTheme: () => {},
  fetchUser: () => {},
  login: () => {},
  logout: () => {},
  requestPasswordReset: () => {},
  resetPassword: () => {},
  currentUser: initUser,
  authenticated: false,
  mode: 'dark',
  error: '',
});

export const useRoot = () => useContext(RootContext);

const Root: React.FC = () => {
  const root = useProvideRoot();
  const { mode } = root;

  return (
    <RootContext.Provider value={root}>
      <ThemeProvider theme={{ ...theme, mode }}>
        <StyledCoreLayout />
      </ThemeProvider>
    </RootContext.Provider>
  );
};

function useProvideRoot() {
  const [currentUser, setCurrentUser] = useState<CurrentUser>(initUser);
  const [error, setError] = useState<string>('');
  const [authenticated, setAuthenticated] = useState(false);
  const [mode, setMode] = useState<'dark' | 'light'>('dark');
  const { data: userData, refetch: fetchUser } = useQuery<UserData>(GET_USER);

  useEffect(() => {
    async function fetchToken() {
      const tokens = await Accounts.refreshSession();
      if (!tokens) {
        setAuthenticated(false);
      } else {
        setAuthenticated(true);
      }
    }
    fetchToken();
  }, []);

  useEffect(() => {
    if (userData && userData.currentUser) {
      const {
        currentUser: { username, id: userId },
      } = userData;
      setCurrentUser({ username, userId });
    }
  }, [userData]);

  const login = async ({ email, password }: LoginDetails) => {
    try {
      const { tokens } = await Accounts.login({
        user: {
          email,
        },
        password,
      });

      if (tokens) {
        setError('');
        setAuthenticated(true);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      const { graphQLErrors } = error;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const [{ message }] = graphQLErrors;

        if (message) {
          setError(message);
        }
      }
      setAuthenticated(false);
    }
  };

  const logout = async () => {
    await Accounts.logout();
    setAuthenticated(false);
  };

  const requestPasswordReset = (email: string) => {
    Accounts.requestPasswordReset(email);
  };

  const resetPassword = (token: string, newPassword: string) => {
    Accounts.resetPassword(token, newPassword);
  };

  const toggleTheme = () => {
    setMode(mode === 'light' ? 'dark' : 'light');
  };

  return {
    toggleTheme,
    fetchUser,
    login,
    logout,
    requestPasswordReset,
    resetPassword,
    currentUser,
    authenticated,
    mode,
    error,
  };
}

export default Root;
