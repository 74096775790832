import React from 'react';
import './overview-list.component.css';

import {
  Title,
  Card,
  CardContent,
  CardFooter,
  CardAction,
  Item,
  Status,
  DateLabel,
  Label,
  Stepper,
  Route,
  Rate,
} from 'fx-ui';
import { FreightRequestInterface } from '../../models/freight-request.models';
import { Link, navigate } from '@reach/router';
import styled from 'styled-components';
import { FreightRequestStatus } from '../../../freight-request/models/freight-request.models';

export interface OverviewListProps {
  freightRequests: FreightRequestInterface[];
}

const StyledCard = styled(Card)`
  margin: 0 20px 25px;

  .card-content {
    padding: 15px 15px 10px;
    .date-created-label {
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
    }
    .ship-name,
    .date-label {
      font-size: 14px;
    }
    .ship-name {
      margin-bottom: 4px;
    }
    .step-container {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const handleMessagesClick = (freightRequestId: string): void => {
  navigate(`/${freightRequestId}?openFixtureLog=true`);
};

const handleEditClick = (freightRequestId: string): void => {
  navigate(`/${freightRequestId}/indicate`);
};

const handleViewChangesClick = (freightRequestId: string): void => {
  navigate(`/${freightRequestId}`);
};

const getActionButtonLabel = (status: FreightRequestStatus) => {
  const { SUBMITTED, OUTDATED, UNREAD } = FreightRequestStatus;
  switch (status) {
    case UNREAD: {
      return 'Indicate';
    }
    case SUBMITTED: {
      return 'Edit';
    }
    case OUTDATED: {
      return 'View changes';
    }
    default:
      return 'Edit';
  }
};

const OverviewList: React.FC<OverviewListProps> = ({ freightRequests }) => {
  return (
    <div className="freight-request-list-container">
      {freightRequests.map(
        (
          {
            freightRequestId,
            details,
            company: { companyName },
            cargo: { quantity, cargoName, cargoType },
            status,
            routes,
            ship,
            unreadMessages = 0,
            created,
          },
          idx
        ) => {
          const cardUrl = `/${freightRequestId}`;
          const shipName = ship ? ship.name : '';

          const { REJECTED, COMPLETED, OUTDATED } = FreightRequestStatus;
          const showStepper = status !== REJECTED;
          const showLeftButton = status !== (REJECTED && COMPLETED);
          const showEditButton = status !== OUTDATED;

          const actionButtonLabel = getActionButtonLabel(status);

          return (
            <StyledCard key={`card-${freightRequestId}`}>
              <CardContent className="card-content">
                <Link to={cardUrl}>
                  <Item>
                    <Status status={status} />
                    <DateLabel
                      className="date-created-label"
                      date={created}
                      format="smart"
                    />
                  </Item>
                  <Title>{companyName}</Title>
                  <Label>
                    {`${cargoName} ${
                      quantity.amount
                    } ${quantity.unit.toUpperCase()}`}
                  </Label>
                </Link>
                <Link to={cardUrl}>
                  <Item>
                    <DateLabel
                      date={[details.layDate, details.cancelling]}
                      format="short"
                      className="date-label"
                    />
                  </Item>
                </Link>
                {showStepper && (
                  <Stepper>
                    {routes.map((route, idx) => {
                      const {
                        offer: { offerAmount, offerUnit },
                      } = route;
                      return (
                        <Link
                          className="step-container"
                          to={cardUrl}
                          key={`route-step-${route.routeId}-${idx}`}>
                          <div>
                            {shipName && (
                              <Label className="ship-name">{shipName}</Label>
                            )}

                            <Route {...route} />
                          </div>
                          <div>
                            <Rate
                              offerAmount={offerAmount}
                              offerUnit={offerUnit}
                            />
                          </div>
                        </Link>
                      );
                    })}
                  </Stepper>
                )}
              </CardContent>
              <CardFooter>
                {showLeftButton &&
                  (showEditButton ? (
                    <CardAction
                      icon="edit"
                      slot="start"
                      onClick={() => handleEditClick(freightRequestId)}>
                      {actionButtonLabel}
                    </CardAction>
                  ) : (
                    <CardAction
                      icon="arrow"
                      slot="start"
                      onClick={() => handleViewChangesClick(freightRequestId)}>
                      {actionButtonLabel}
                    </CardAction>
                  ))}
                <CardAction
                  icon="message"
                  slot="end"
                  badge={unreadMessages}
                  onClick={() => handleMessagesClick(freightRequestId)}>
                  Messages
                </CardAction>
              </CardFooter>
            </StyledCard>
          );
        }
      )}
    </div>
  );
};

export default OverviewList;
