import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

interface LoadingProps {
  topMessage?: string;
  bottomMessage?: string;
  actionMessage?: string;
  actionTimer?: number;
  action?: () => void;
}

const dotAnimation = keyframes`
  50% {
    transform: translateX(96px)
  }
`;

const dotsAnimation = keyframes`
  50% {
    transform: translateX(-31px)
  }
`;

const StyledLoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.backgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

const StyledLoading = styled.div`
  position: absolute;
  width: 142px;
  height: 40px;
  filter: contrast(20);
  background: ${({ theme }) => theme.backgroundColor};

  .dot {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 12px;
    left: 15px;
    filter: blur(4px);
    background: ${({ theme }) => theme.textColor};
    border-radius: 50%;
    transform: translateX(0);
    animation: ${dotAnimation} 2.8s infinite;
  }
  .dots {
    transform: translateX(0);
    margin-top: 12px;
    margin-left: 31px;
    animation: ${dotsAnimation} 2.8s infinite;

    span {
      display: block;
      float: left;
      width: 16px;
      height: 16px;
      margin-left: 16px;
      filter: blur(4px);
      background: ${({ theme }) => theme.textColor};
      border-radius: 50%;
    }
  }
`;

const StyledSlowLoader = styled.div<{ show: boolean }>`
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: opacity 0.5s ease-in-out;
  opacity: ${({ show }) => (show ? 1 : 0)};
`;

const Loading: React.FC<LoadingProps> = ({
  topMessage = 'This is taking longer than expected',
  bottomMessage = 'It might help to reload the page',
  actionMessage = 'Click here to reload',
  action = () => window.location.reload(),
  actionTimer = 6000,
}) => {
  const [loadingToLong, setLoadingToLong] = useState(false);

  useEffect(() => {
    // If loading too long, give customer option to refresh page
    const timer = setTimeout(() => {
      setLoadingToLong(true);
    }, actionTimer);
    return () => clearTimeout(timer);
  });

  return (
    <StyledLoadingContainer>
      <StyledSlowLoader show={loadingToLong}>
        <p>{topMessage}</p>
      </StyledSlowLoader>
      <StyledLoading>
        <span className="dot"></span>
        <div className="dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </StyledLoading>
      <StyledSlowLoader show={loadingToLong}>
        <p>{bottomMessage}</p>
        <p onClick={action}>{actionMessage}</p>
      </StyledSlowLoader>
    </StyledLoadingContainer>
  );
};

export default Loading;
