/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  createContext,
  useContext,
  useState,
  FC,
  FunctionComponent,
} from 'react';
import {
  FreightRequestInterface,
  ShippingRoute,
  Ship,
} from '../models/freight-request.models';
import Loading from 'app/shared/Loading/Loading';
import IndicateOfferPage from '../components/indicate-offer/indicate-offer.page';
import { RouteComponentProps } from '@reach/router';
import { MasterShip } from '../models/master-ships.models';
import { useMasterShips } from '../hooks/useMasterShips';
import { useFreightRequest } from '../hooks/useFreightRequest';
import ErrorContainer from 'app/shared/Error/ErrorContainer';

interface IndicateOfferContainerProps extends FunctionComponent {
  freightRequestId: string;
}

export interface IndicateOfferState {
  filterMasterShips: (shipName: string) => void;
  upsertOffer: (routes: Partial<ShippingRoute>[], ship?: Partial<Ship>) => void;
  freightRequest: FreightRequestInterface | null;
  masterShips: MasterShip[];
  loadingFreightRequest: boolean;
  loadingMasterShips: boolean;
  freightRequestError: Error | null;
}

const IndicateOfferContext = createContext<IndicateOfferState>({
  filterMasterShips: (shipName: string) => {},
  upsertOffer: () => {},
  freightRequest: null,
  masterShips: [],
  loadingFreightRequest: false,
  loadingMasterShips: false,
  freightRequestError: null,
});

const IndicateOfferContainer: FC<
  RouteComponentProps<IndicateOfferContainerProps>
> = ({ freightRequestId }) => {
  const indicateOffer = useProvideIndicateOffer(freightRequestId);
  const {
    loadingFreightRequest,
    freightRequestError,
    freightRequest,
  } = indicateOffer;

  if (loadingFreightRequest) {
    return <Loading />;
  }

  if (freightRequestError) {
    return (
      <ErrorContainer
        title="Error getting Freight Request"
        message={JSON.stringify(freightRequestError, undefined, 2)}
      />
    );
  }

  return (
    <IndicateOfferContext.Provider value={indicateOffer}>
      {freightRequest ? (
        <IndicateOfferPage freightRequest={freightRequest} />
      ) : (
        <ErrorContainer title="No FR" message="" />
      )}
    </IndicateOfferContext.Provider>
  );
};

export const useIndicateOfferContext = () => {
  return useContext(IndicateOfferContext);
};

function useProvideIndicateOffer(freightRequestId: string = '') {
  const {
    loading: loadingFreightRequest,
    freightRequest,
    upsertOffer,
    error: freightRequestError,
  } = useFreightRequest(freightRequestId);
  const [shipName, setShipName] = useState('');
  const { loading: loadingMasterShips, masterShips } = useMasterShips(shipName);

  const filterMasterShips = (shipNameFilter: string): void => {
    if (shipNameFilter.length > 2) {
      setShipName(shipNameFilter);
    }
  };

  return {
    filterMasterShips,
    upsertOffer,
    freightRequest,
    masterShips,
    loadingFreightRequest,
    loadingMasterShips,
    freightRequestError,
  };
}

export default IndicateOfferContainer;
