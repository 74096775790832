import React, { useState } from 'react';
import { Card, CardContent, Title, Item, Label, Icon } from 'fx-ui';
import { SubTermInterface } from 'app/freight-request/models/freight-request.models';
import styled from 'styled-components';
import { hideKeyframes, revealKeyframes } from 'fx-ui/theme';

interface FreightRequestDetailsSubTermsProps {
  subTerms: SubTermInterface[];
}

interface ListItemProps {
  reference: string;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5em 0;
  .sub-title {
    margin-bottom: 6px;
  }
  .list-header {
    label {
      font-size: 15px;
    }
  }
  .list-item {
    font-size: 14px;
    line-height: 19px;
    &:first-of-type {
      margin-top: 15px;
    }
    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }

  .ammendments-list {
    position: relative;
    max-height: 0;
    margin-block-end: 0;
    animation: ${hideKeyframes} 0.3s ease;
    animation-fill-mode: forwards;
    overflow: hidden;
  }

  &.selectedRow {
    .ammendments-list {
      animation: ${revealKeyframes} 0.5s ease;
      animation-fill-mode: forwards;
    }
  }
`;

const StyledTitle = styled(Title)`
  margin-bottom: 8px;
`;

const StyledList = styled.ul`
  margin-top: 0;
  padding: 0 1em;
  list-style-type: none;
`;

const StyledListItem = styled.li<ListItemProps>`
  display: flex;

  &:before {
    content: '${({ reference }) => reference}';
    color: ${({ theme }) => theme.palette.white};
    position: relative;
    margin-right: 10px;
    font-weight: 600;
    margin-left: -1em;
  }
`;

function textToKey(text: string) {
  return text.replace(/\s/g, '');
}

const FreightRequestDetailsSubTerms: React.FC<
  FreightRequestDetailsSubTermsProps
> = ({ subTerms = [] }) => {
  const [openedDetailIdx, setOpenedDetailIdx] = useState<number | null>(null);
  const { length: amountSubTerms } = subTerms;

  if (!(amountSubTerms > 0)) {
    return null;
  }

  return (
    <Card>
      <CardContent>
        <StyledTitle subtitle>
          Clauses & amendments ({amountSubTerms})
        </StyledTitle>
        {subTerms.map(({ description, amendments }, subtermIdx) => {
          const isSelected = openedDetailIdx === subtermIdx;
          const subtermsKey = `subTerm-details-${subtermIdx}-${textToKey(
            description
          )}`;

          return (
            <StyledContainer
              key={subtermsKey}
              className={isSelected ? 'selectedRow' : ''}>
              <Item
                className="list-header"
                onClick={() =>
                  setOpenedDetailIdx(isSelected ? null : subtermIdx)
                }>
                <Label>Subject: {description}</Label>
                <Icon name="caret" fill="white" rotate={isSelected ? 180 : 0} />
              </Item>
              <StyledList className="ammendments-list">
                {amendments.map(({ reference, description }, amendmentIdx) => {
                  const amendmentKey = textToKey(
                    `subTerm-${subtermIdx}-${textToKey(
                      description
                    )}-amendment-detail-${reference || amendmentIdx}`
                  );

                  return (
                    <StyledListItem
                      className="list-item"
                      reference={reference}
                      key={amendmentKey}>
                      {description}
                    </StyledListItem>
                  );
                })}
              </StyledList>
            </StyledContainer>
          );
        })}
      </CardContent>
    </Card>
  );
};

export default FreightRequestDetailsSubTerms;
