import React, { FC, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  background-color: ${({ theme }) => theme.inputBackgroundColor};
  color: ${({ theme }) => theme.inputTextColor};
  font-family: ${({ theme }) => theme.arialFontFamily};
  font-size: 14px;
  font-weight: 400;
  max-width: 100%;
  height: 36px;
  border-radius: 6px;
  border: none;
  padding: 0 10px;
  outline: none;
`;

const Input: FC<InputHTMLAttributes<HTMLInputElement>> = ({
  type,
  ...props
}) => {
  return <StyledInput type={type || 'text'} {...props} />;
};

export default Input;
