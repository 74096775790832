import React from 'react';
import styled from 'styled-components';
import { Title, Button, Toolbar } from 'fx-ui';
import { navigate } from '@reach/router';

interface ErrorProps {
  title: string;
  message: string;
  buttonText?: string;
}

const StyledErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .error-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  background-color: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  font-size: 18px;
  white-space: pre;
  overflow: auto;
  border: none;
  padding: 20px 0;
`;

const ErrorContainer: React.FC<ErrorProps> = ({
  title,
  message,
  buttonText = 'OK',
}) => {
  const { length } = message.split(/\r\n|\r|\n/);
  return (
    <StyledErrorContainer>
      <Toolbar backAction={() => navigate('/')} />
      <div className="error-content">
        <Title subtitle>{title}</Title>
        <StyledTextArea value={message} rows={length} disabled />
        <Button onClick={() => navigate('/')}>{buttonText}</Button>
      </div>
    </StyledErrorContainer>
  );
};

export default ErrorContainer;
