import { useQuery } from '@apollo/react-hooks';
import { LIST_MASTER_SHIPS } from '../graphql';
import { MasterShip } from '../models/master-ships.models';
import { useState, useEffect } from 'react';
import { ApolloError } from 'apollo-boost';

interface Data {
  masterShips: MasterShip[];
}
interface Variables {
  shipName: string;
}

type HookProps = (
  shipName: string
) => { masterShips: MasterShip[]; loading: boolean };

const useMasterShips: HookProps = shipName => {
  const [masterShips, setMasterShips] = useState<MasterShip[]>([]);
  const [loading, setLoading] = useState(true);

  const { error, data } = useQuery<Data, Variables>(LIST_MASTER_SHIPS, {
    variables: { shipName },
  });

  useEffect(() => {
    if (data) {
      setMasterShips(data.masterShips || []);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setLoading(false);
      throw new ApolloError(error);
    }
  }, [error]);

  if (!shipName) {
    return {
      loading: false,
      masterShips: [],
    };
  }

  return {
    loading,
    masterShips,
  };
};

export { useMasterShips };
