import React from 'react';
import styled from 'styled-components';

const StyledCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  word-wrap: break-word;
  min-height: 100px;
  max-width: 100%;
  background-color: ${({ theme }) => theme.cardBackgroundColor};
  border-radius: 6px;
  border: none;
  margin: 10px 20px;
  height: fit-content;
`;

const Card: React.FC = ({ children, ...props }) => {
  return <StyledCard {...props}>{children}</StyledCard>;
};

export default Card;
