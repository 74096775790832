import React, { ReactNodeArray, useState, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Container } from '../Container';
import SwipeableViews from 'react-swipeable-views';

interface StepProps extends HTMLAttributes<HTMLDivElement> {
  index?: number;
  currentStepIndex?: number;
}

interface StyledStepProps {
  index: number;
  currentStepIndex: number;
}

const StyledStepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const StyledStep = styled.div<StyledStepProps>`
  width: 100%;
  height: 2px;
  margin: 10px 2px;
  background: ${({ theme, currentStepIndex, index }) =>
    index === currentStepIndex ? theme.palette.white : theme.palette.medium};
`;

const Stepper: React.FC<StepProps> = ({ children }) => {
  const [, setPreviousStep] = useState(0);
  const [currentStepIndex, setCurrentStep] = useState(0);

  const handleClick = (currentStep: number, nextStep: number): void => {
    setPreviousStep(currentStep);
    setCurrentStep(nextStep);
  };

  if (!children) {
    return null;
  }

  return (
    <StyledStepperContainer>
      <Container>
        {(children as ReactNodeArray).map((child, idx: number) => {
          return (
            <StyledStep
              key={`step-${idx}`}
              index={idx}
              currentStepIndex={currentStepIndex}
              onClick={() => handleClick(currentStepIndex, idx)}
            />
          );
        })}
      </Container>
      <SwipeableViews
        className="stepper-swipeable-views"
        onChangeIndex={currentStep => setCurrentStep(currentStep)}
        index={currentStepIndex}>
        {children}
      </SwipeableViews>
    </StyledStepperContainer>
  );
};

export default Stepper;
