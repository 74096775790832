import React from 'react';
import styled from 'styled-components';
import Div100vh from 'react-div-100vh';
import { Toolbar, Footer, ChatInput, Label } from 'fx-ui';
import FixtureLogDiscussionList from './message-log.component';
import { navigate } from '@reach/router';
import { Cargo, DiscussionItem } from '../../models/freight-request.models';

interface FixtureLogProps {
  companyName: string;
  cargo: Cargo;
  discussion: DiscussionItem[];
  sendMessage: (message: string) => void;
  closeAction: () => void;
}

const StyledPageContainer = styled(Div100vh)`
  display: grid;
  grid-template: auto 1fr auto / 100%;

  .fixture-log-toolbar {
    display: flex;
    flex-direction: row;

    .header-label-wrapper {
      display: flex;
      flex-direction: column;

      .header-label {
        font-size: 13px;
        &:first-of-type {
          font-weight: 700;
        }
      }
    }
  }
`;

const FixtureLog: React.FC<FixtureLogProps> = ({
  companyName,
  cargo,
  discussion,
  closeAction,
  sendMessage,
}) => {
  const { cargoName, quantity } = cargo;
  const { amount, unit } = quantity;
  return (
    <StyledPageContainer>
      <Toolbar
        className="fixture-log-toolbar"
        closeAction={closeAction}
        overviewAction={() => navigate('/')}>
        <div className="header-label-wrapper">
          <Label className="header-label">{companyName}</Label>
          <Label className="header-label">{`${cargoName} / ${amount} ${unit}`}</Label>
        </div>
      </Toolbar>
      <FixtureLogDiscussionList discussion={discussion} />
      <Footer>
        <ChatInput sendMessage={sendMessage} />
      </Footer>
    </StyledPageContainer>
  );
};

export default FixtureLog;
