import { keyframes } from 'styled-components';
import theme from 'styled-theming';

type Theme = typeof theme;
export interface FxThemeInterface {
  mode: 'dark' | 'light';
  colors: { [name: string]: string };
  palette: { [color: string]: Theme };
  backgroundColor: Theme;
  textColor: Theme;
  buttonBackgroundColor: Theme;
  buttonTextColor: Theme;
  chipBackgroundColor: Theme;
  cardBackgroundColor: Theme;
  inputBackgroundColor: Theme;
  inputTextColor: Theme;
  messageTextColor: Theme;
  baseFontFamily: Theme;
  arialFontFamily: Theme;
  baseBorderColor: Theme;
}

export const mode = 'dark';

export const colors = {
  cerulean: '#23b1ec' /** Primary blue */,
  malibu: '#75ddff' /** Secondary blue */,
  vividTangerine: '#ff8080' /** Danger red */,
  pastelGreen: '#59d982' /** Success green */,
  goldenFizz: '#f0ff47' /** Warning yellow */,
  silverChalice: '#adadad' /** Light gray (labels) */,
  doveGray: '#666666' /** Medium gray (borders) */,
  mineShaft: '#383838' /** Gray */,
  codGray: '#1a1a1a' /** Dark gray */,
  black: '#000000' /** Black */,
  white: '#ffffff' /** White */,
};

export const palette = {
  primary: theme('mode', { dark: colors.cerulean, light: colors.cerulean }),
  primaryTint: theme('mode', { dark: colors.malibu, light: colors.malibu }),
  danger: theme('mode', {
    dark: colors.vividTangerine,
    light: colors.vividTangerine,
  }),
  warning: theme('mode', { dark: colors.goldenFizz, light: colors.goldenFizz }),
  success: theme('mode', {
    dark: colors.pastelGreen,
    light: colors.pastelGreen,
  }),
  silver: theme('mode', {
    dark: colors.silverChalice,
    light: colors.silverChalice,
  }),
  medium: theme('mode', {
    dark: colors.doveGray,
    light: colors.doveGray,
  }),
  gray: theme('mode', {
    dark: colors.mineShaft,
    light: colors.mineShaft,
  }),
  black: theme('mode', {
    dark: colors.black,
    light: colors.black,
  }),
  white: theme('mode', {
    dark: colors.white,
    light: colors.white,
  }),
};

/** ---------------- GLOBAL VARIABLES ---------------- **/

/** Global background color */
export const backgroundColor = theme('mode', {
  light: colors.white,
  dark: colors.black,
});

/** Global text color */
export const textColor = theme('mode', {
  light: colors.black,
  dark: colors.white,
});

/** Global border color */
export const baseBorderColor = theme('mode', {
  light: '#222',
  dark: palette.medium,
});

/** Global font families */
export const baseFontFamily = `'Roboto', sans-serif`;
export const arialFontFamily = `'Arial', sans-serif`;

/** ---------------- STYLED COMPONENTS VARABLES ---------------- **/

/** Button background color */
export const buttonBackgroundColor = theme('mode', {
  light: '#222',
  dark: palette.primary,
});

/** Button text color */
export const buttonTextColor = theme('mode', {
  light: '#eee',
  dark: colors.white,
});

/** Card background color */
export const cardBackgroundColor = theme('mode', {
  light: '#ccc',
  dark: colors.codGray,
});

/** Input background color */
export const inputBackgroundColor = theme('mode', {
  light: '#222',
  dark: colors.mineShaft,
});

/** Input text color */
export const inputTextColor = theme('mode', {
  light: colors.black,
  dark: colors.white,
});

/** Message text color */
export const messageTextColor = theme('mode', {
  light: colors.codGray,
  dark: colors.codGray,
});

/** Icon fill color */
export const iconFillColor = theme('mode', {
  light: colors.black,
  dark: colors.white,
});

/** KEYFRAMES **/

export const revealKeyframes = keyframes`
  0% {
    max-height: 0;
    overflow: hidden;
    margin-bottom:0;
  }
  25% {
    margin-bottom:15px;
  }
  99% {
    max-height: 100vh;
    margin-bottom:15px;
  }
  100% {
    max-height: 1000vh;
    overflow: auto;
    margin-bottom:15px;
  }
`;
export const hideKeyframes = keyframes`
  0% {
    max-height: 1000vh;
    overflow: auto;
    margin-bottom:15px;
  }
  1% {
    max-height: 100vh;
    margin-bottom:15px;
  }
  100% {
    max-height: 0;
    overflow: hidden;
    margin-bottom:0;
  }
`;
