import React, { useState, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Button, Label, Textarea } from 'fx-ui';
import { useTranslation } from 'react-i18next';

interface RejectOfferPageProps extends HTMLAttributes<HTMLDivElement> {
  rejectOffer: (reason: string, comment: string) => void;
  cancelRejection: () => void;
}

const StyledPageContainer = styled.div`
  @keyframes slide-in {
    from {
      transform: translateY(200%);
    }
    to {
      transform: translateY(0);
    }
  }

  position: absolute;
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 20px;
  align-self: flex-end;
  transform: translateY(100%);
  transition: all 0.25s ease;

  .option {
    transition: all 0.4s ease;
    background-color: ${({ theme }) => theme.inputBackgroundColor};
    color: white;
    font-size: 18px;
    padding: 15px 20px;
    border-radius: 6px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:last-of-type {
      margin-bottom: 40px;
    }

    .checkmark {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 22px;
      width: 22px;
      background: none;
      border: 3px solid #adadad;
      border-radius: 50%;
      margin-right: 15px;
      transition: all 0.2s ease;
      &::before {
        content: ' ';
        height: 10px;
        width: 10px;
        border-radius: 100%;
        display: block;
        opacity: 0;
        transition: all 0.2s ease;
      }
    }

    &.selected {
      background-color: ${({ theme }) => theme.palette.primary};
      color: black;
      .checkmark {
        border-color: black;
        transition: all 0.2s ease;
        &::before {
          background-color: black;
          opacity: 1;
          transition: all 0.2s ease;
        }
      }
    }

    input {
      display: none;
    }
  }
  .anything {
    font-family: Roboto;
    font-size: 14px;
    color: #adadad;
    font-weight: 500;
    text-decoration: none solid rgb(173, 173, 173);
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  textarea {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 25px;
    font-size: 14px;
  }

  button {
    width: 100%;
    &:first-of-type {
      margin-bottom: 15px;
    }
  }
`;

const StyledButton = styled(Button)`
  border: 2px solid ${({ theme }) => theme.palette.silver};
  color: ${({ theme }) => theme.palette.silver};
  background: transparent;
  &:hover {
    background: ${({ theme }) => theme.palette.silver};
  }
`;

const RejectOfferPage: React.FC<RejectOfferPageProps> = ({
  rejectOffer,
  cancelRejection,
  ...props
}) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');
  const options = [t('No ship available'), t('Do not wish to offer/indicate')];

  const handleSelectOption = (optionId: number) => {
    setSelectedOption(optionId);
    setReason(options[optionId]);
  };

  return (
    <StyledPageContainer {...props}>
      {options.map((option, idx) => {
        const isSelected = selectedOption === idx;
        const optionId = 'option-' + idx;
        return (
          <label
            htmlFor={optionId}
            className={`option${isSelected ? ' selected' : ''}`}
            key={optionId}
            onTouchStart={() => handleSelectOption(idx)}>
            <span className="checkmark" />
            <input
              type="radio"
              id={optionId}
              name="rejection-option"
              value={option}
              checked={isSelected}
              readOnly
            />
            {option}
          </label>
        );
      })}
      <div>
        <Label className="anything">{t('Add comment')}</Label>
        <Textarea
          name="comment"
          value={comment}
          onChange={({ target: { value } }) => setComment(value)}
        />
      </div>
      <Button size="large" onTouchStart={() => rejectOffer(reason, comment)}>
        {t('Reject request')}
      </Button>
      <StyledButton size="large" onTouchStart={() => cancelRejection()}>
        {t('Cancel')}
      </StyledButton>
    </StyledPageContainer>
  );
};

export default RejectOfferPage;
