import React from 'react';
import styled from 'styled-components';

interface FooterProps extends React.HTMLAttributes<HTMLDivElement> {
  hidden?: boolean;
}

const StyledFooter = styled.footer`
  position: fixed;
  display: flex;
  bottom: 0;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 65px;
  background-color: ${({ theme }) => theme.palette.gray};
  color: ${({ theme }) => theme.palette.silver};
  font-family: ${({ theme }) => theme.baseFontFamily};
  z-index: 10;
`;

const Footer: React.FC<FooterProps> = ({ children, ...props }) => {
  return <StyledFooter {...props}>{children}</StyledFooter>;
};

export default Footer;
