import React from 'react';
import styled from 'styled-components';

interface ItemProps extends React.HTMLAttributes<HTMLDivElement> {
  lines?: boolean;
  flex?: 'start' | 'end';
  className?: string;
}

interface StyledItemProps {
  lines?: boolean;
  flex?: 'start' | 'end';
}

const StyledItem = styled.div<StyledItemProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ flex }) => (flex ? `flex-${flex}` : 'space-between')};
  align-items: center;
  font-family: ${({ theme }) => theme.baseFontFamily};
  color: ${({ theme }) => theme.colors.silverChalice};
  padding: 0.25em 0;
  border-bottom: 1px solid
    ${({ theme, lines = false }) => (lines ? theme.baseBorderColor : 'none')};
`;

const Item: React.FC<ItemProps> = ({ children, ...props }) => {
  return <StyledItem {...props}>{children}</StyledItem>;
};

export default Item;
