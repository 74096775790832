import gql from 'graphql-tag';

export const SUBSCRIBE_SINGLE_FREIGHT_REQUEST = gql`
  subscription onFreightRequestChanges($freightRequestId: ID!) {
    freightRequest: subscribeFreightRequest(
      freightRequestId: $freightRequestId
    ) {
      freightRequestId
      company {
        companyName
      }
      details {
        layDate
        cancelling
        offerWithin
        offerValidTo
        offerSubsDue
      }
      cargo {
        cargoName
        quantity {
          unit
          amount
          tolerance
        }
      }
      routes {
        routeId
        addId
        loadLocations {
          locationName
          locationCode
        }
        dischargeLocations {
          locationName
          locationCode
        }
        loadComment
        dischargeComment
        offer {
          offerAmount
          offerUnit
          offerComment
        }
      }
      ship {
        id
        name
        laytimeHours
        overage
        demurrageRate
        comments
      }
      requirements {
        subject
        type
        details {
          reference
          description
        }
      }
      documents {
        documentName
        documentUrl
      }
      subTerms {
        description
        amendments {
          reference
          description
        }
      }
      conditions
      discussion {
        value
        created
        user {
          userId
          firstName
          lastName
        }
        type
      }
      unreadMessages
      status
      modified
      created
    }
  }
`;
