import React, { useState } from 'react';
import styled from 'styled-components';
import { FreightRequestInterface } from '../../models/freight-request.models';
import { Title, Toolbar, Icon, Footer, Button } from 'fx-ui';
import FreightRequestDetailsInfo from './details-info.component';
import FreightRequestDetailsDeadlines from './details-deadlines.component';
import FreightRequestDetailsRequirements from './details-requirements.component';
import FreightRequestDetailsDocuments from './details-documents.components';
import FreightRequestDetailsConditions from './details-conditions.component';
import FreightRequestDetailsSubTerms from './details-subterms.component';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import RejectOfferPage from './reject-offer.page';
import { FixtureLogContainer } from 'app/freight-request';

interface FreightRequestDetailsPageProps {
  freightRequest: FreightRequestInterface;
  openFixtureLog: boolean;
  rejectFreightRequest: (reason: string, comment: string) => void;
}

interface StyledPageContainerProps {
  showRejectOffer: boolean;
}

interface StyledSidebarProps {
  showSidebar: boolean;
}

const StyledSidebar = styled.aside<StyledSidebarProps>`
  position: fixed;
  background-color: ${({ theme }) => theme.backgroundColor};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: transform 0.25s ease
    ${({ showSidebar }) => (showSidebar ? '' : ', opacity 0.25s ease')};
  transform: translateX(-${({ showSidebar }) => (showSidebar ? 100 : 0)}%);
  left: 100%;
  z-index: 100;

  opacity: ${({ showSidebar }) => (showSidebar ? 1 : 0)};

  @media screen and (min-width: 768px) {
    width: 50%;
    opacity: 1;
    transform: translateX(-100%);
  }
`;

const StyledPageContainer = styled.div<StyledPageContainerProps>`
  height: 100%;
  display: grid;
  grid-template: auto 1fr auto / 100%;

  @media screen and (min-width: 768px) {
    width: 50%;
    border-right: 2px solid white;

    .reveal-log-button {
      display: none;
    }

    .details-page-footer-container {
      width: 50%;
      border-right: 2px solid white;
    }
  }

  .freight-request-details-page-content {
    overflow-x: hidden;
    height: calc(100vh - 65px);
    > div:last-child {
      margin-bottom: 100px;
    }
  }

  .details-page-footer-container {
    overflow: hidden;
    transition: all 0.4s ease;
    background-color: ${({ theme, showRejectOffer }) =>
      showRejectOffer ? theme.cardBackgroundColor : theme.palette.gray};
    ${({ showRejectOffer }) =>
      showRejectOffer ? `height: calc(100vh - 65px);` : ''}
    & > button {
      transition: all 0.5s ease;
    }
    .show-reject-page {
      transition: all 5s ease;
    }
    &.show-reject-page {
      .rejectoffer-page {
        transform: translateY(-20%);
        transition: all 0.6s ease;
        transition-delay: 0.28s;
      }
      & > button {
        opacity: 0;
        transform: translateY(50px);
        align-self: flex-end;
        transition: all 0.2s ease;
      }
    }
  }
`;

const StyledMessageIcon = styled(Icon)`
  margin-left: 10px;
`;

const StyledPageTitle = styled(Title)`
  margin: 1em;
`;

const StyledButton = styled(Button)`
  border: 2px solid ${({ theme }) => theme.palette.silver};
  color: ${({ theme }) => theme.palette.silver};
  background: transparent;
  &:hover {
    background: ${({ theme }) => theme.palette.silver};
  }
`;

/**
 * @todo Safeguard back button
 * @body Need to check that this doesn't exit the app if coming from email link
 */
const handleBackAction = () => {
  navigate('/');
};

const FreightRequestDetailsPage: React.FC<FreightRequestDetailsPageProps> = ({
  freightRequest,
  openFixtureLog = false,
  rejectFreightRequest,
}) => {
  const { t } = useTranslation();
  const {
    freightRequestId,
    details,
    conditions,
    documents,
    subTerms,
    requirements,
  } = freightRequest;
  const { offerWithin, offerValidTo, offerSubsDue } = details;
  const [showFixtureLog, setShowFixtureLog] = useState(openFixtureLog);
  const [showRejectPage, setShowRejectPage] = useState(false);
  return (
    <>
      <StyledSidebar showSidebar={showFixtureLog}>
        <FixtureLogContainer
          freightRequestId={freightRequestId}
          closeFixtureLog={() => {
            setShowFixtureLog(false);
            navigate(`/${freightRequestId}`);
          }}
        />
      </StyledSidebar>

      <StyledPageContainer showRejectOffer={showRejectPage}>
        <Toolbar backAction={handleBackAction}>
          <Title
            className="reveal-log-button"
            subtitle
            onClick={() => setShowFixtureLog(true)}>
            Fixture log
          </Title>
          <StyledMessageIcon
            className="reveal-log-button"
            name="message"
            fill="white"
            onClick={() => setShowFixtureLog(true)}
          />
        </Toolbar>
        <div className="freight-request-details-page-content">
          <StyledPageTitle>{t('Details')}</StyledPageTitle>
          <FreightRequestDetailsInfo freightRequest={freightRequest} />
          <FreightRequestDetailsDeadlines
            offerWithin={offerWithin}
            offerValidTo={offerValidTo}
            offerSubsDue={offerSubsDue}
          />
          <FreightRequestDetailsConditions conditions={conditions} />
          <FreightRequestDetailsDocuments documents={documents} />
          <FreightRequestDetailsRequirements requirements={requirements} />
          <FreightRequestDetailsSubTerms subTerms={subTerms} />
        </div>
        <Footer
          className={`details-page-footer-container${
            showRejectPage ? ' show-reject-page' : ''
          }`}>
          <RejectOfferPage
            className="rejectoffer-page"
            rejectOffer={(reason: string, comment: string) => {
              rejectFreightRequest(reason, comment);
              setShowRejectPage(false);
            }}
            cancelRejection={() => setShowRejectPage(false)}
          />
          <Button onClick={() => navigate(`${freightRequestId}/indicate`)}>
            {t('Indicate')}
          </Button>
          <StyledButton onTouchStart={() => setShowRejectPage(true)}>
            {t('Reject')}
          </StyledButton>
        </Footer>
      </StyledPageContainer>
    </>
  );
};

export default FreightRequestDetailsPage;
