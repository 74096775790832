import React from 'react';
import styled from 'styled-components';

const StyledCardFooter = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const CardFooter: React.FC = ({ children }) => {
  return <StyledCardFooter>{children}</StyledCardFooter>;
};

export default CardFooter;
