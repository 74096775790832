import React, { useState } from 'react';
import styled from 'styled-components';
import { Container } from '../Container';
import { Icon } from '../Icon';

interface ChatInputProps {
  sendMessage: (message: string) => void;
}

const StyledContainer = styled(Container)<{ isFocused: boolean }>`
width:100%;
padding-left:1.5rem;
padding-right:1.5rem;
position:relative;

  .chat-input-wrapper {
    position: relative;
    width:100%;
  }

@keyframes move-in {
  from {
    transform: translateX(250%);
    opacity:0;
  }
  to {
    transform: translateX(0);
    opacity:1;
  }
}

  .chat-input-send-icon {
    margin: 0 10px 0 20px;
    cursor: pointer;
    opacity:0.6;
    transition:all 0.3s ease;
  }

  label {
    display:flex;
    align-items:center;
  }

  input {
    background-color: ${({ theme }) => theme.palette.medium};
    color: ${({ theme }) => theme.inputTextColor};
    font-family: ${({ theme }) => theme.arialFontFamily};
    font-size: 14px;
    font-weight: 400;
    /*width: ${({ isFocused }) => (isFocused ? '269px' : '319px')}; */
    width:100%;
    height: 38px;
    border-radius: 50px;
    border: none;
    padding: 0 35px 0 10px;
    outline: none;
    box-sizing: border-box;
    transition:all 0.3s ease;
    &:focus + .chat-input-send-icon {
      opacity:1;
    }
  }



  .chat-input-attachment-icon {
    position: absolute;
    display:none;
    top: 15%;
    right: ${({ isFocused }) => (isFocused ? '5%' : '3%')};
  }
`;

const ChatInput: React.FC<ChatInputProps> = ({ sendMessage }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [message, setMessage] = useState('');

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  return (
    <StyledContainer isFocused={isFocused}>
      <form
        className="chat-input-wrapper"
        onSubmit={event => {
          if (event) {
            event.preventDefault();
            sendMessage(message);
            setMessage('');
          }
        }}>
        <label>
          <input
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={message}
            onChange={({ target: { value } }) => setMessage(value)}
          />

          <Icon
            className="chat-input-send-icon"
            name="send"
            fill="primary"
            scale={1.5}
            onTouchStart={() => {
              sendMessage(message);
              setMessage('');
            }}
            onClick={() => {
              sendMessage(message);
              setMessage('');
            }}
          />
        </label>
      </form>
    </StyledContainer>
  );
};

export default ChatInput;
