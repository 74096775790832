import React from 'react';
import styled from 'styled-components';
import { Label } from '../Label';

interface ListProps {
  items?: string[];
}

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  max-width: 100%;
  margin-top: 0;
  padding: 0 1em;
  list-style-type: none;
  position: relative;
`;

const StyledListItem = styled.li`
  margin-bottom: 5px;
  &:before {
    content: ' ';
    background-color: ${({ theme }) => theme.palette.primary};
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    left: 0;
    transform: translateY(4px);
  }
`;

const List: React.FC<ListProps> = ({ children, items = [], ...props }) => {
  return (
    <StyledList className="list-ul" {...props}>
      {items &&
        items.map(item => (
          <StyledListItem {...props} key={`list-item-${item}`}>
            <Label small>{item}</Label>
          </StyledListItem>
        ))}
      {children}
    </StyledList>
  );
};

export default List;
