import React, { FC, TextareaHTMLAttributes } from 'react';
import styled from 'styled-components';

const StyledTextarea = styled.textarea`
  background: ${({ theme }) => theme.palette.gray};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.arialFontFamily};
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
  border: none;
  outline: none;
  padding: 10px;
`;

const Textarea: FC<TextareaHTMLAttributes<HTMLTextAreaElement>> = ({
  rows,
  ...props
}) => {
  return <StyledTextarea rows={rows || 3} {...props} />;
};

export default Textarea;
