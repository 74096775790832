import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-boost';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink, split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { WebSocketLink } from 'apollo-link-ws';
import { environment } from 'environments/environment';
// import { authLink } from './accounts';

// import { AccountsClient } from '@accounts/client';
// import { AccountsClientPassword } from '@accounts/client-password';
// import GraphQLClient from '@accounts/graphql-client';

import ApolloAccounts from './apollo-accounts';
import { setContext } from 'apollo-link-context';
// import { accountsLink } from '@accounts/apollo-link';

interface Definintion {
  kind: string;
  operation?: string;
}

const { uri, wsUri } = environment.graphql;

const httpLink = new HttpLink({
  uri,
  credentials: 'include',
});

const wsLink = new WebSocketLink({
  uri: wsUri,
  options: {
    // Automatic reconnect in case of connection error
    reconnect: true,
  },
});

const terminatingLink = split(
  ({ query }) => {
    const { kind, operation }: Definintion = getMainDefinition(query);
    // const { kind, operation } = getMainDefinition(query);
    // If this is a subscription query, use wsLink, otherwise use httpLink
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink
);

const authLink = setContext(async (_, { headers: headersWithoutTokens }) => {
  // get the authentication token from local storage if it exists
  // const tokens = await accountsClient.refreshSession();

  const headers = { ...headersWithoutTokens };

  const token = localStorage.getItem('accounts:accessToken');

  if (token) {
    headers.Authorization = 'Bearer ' + token;
  }
  // return the headers to the context so httpLink can read them
  return {
    headers,
  };
});

const link = ApolloLink.from([authLink, terminatingLink]);
const inMemoryCache = new InMemoryCache();

const apolloClient = new ApolloClient({
  link,
  cache: inMemoryCache,
});

// // accounts JS
// const accountsGraphQL = new GraphQLClient({ graphQLClient: apolloClient });
// const accountsClient = new AccountsClient({}, accountsGraphQL);
// const accountsPassword = new AccountsClientPassword(accountsClient, {
//   hashPassword: (password: string) => password,
// });

// apolloClient.link = ApolloLink.from([authLink, terminatingLink]);

// export { apolloClient, accountsClient, accountsGraphQL, accountsPassword };

export default new ApolloAccounts(apolloClient, {});
