import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FreightRequestInterface } from '../../models/freight-request.models';
import logo from 'app/fx_logo.svg';
import { Title, Toolbar, Label, Footer, Button, Item } from 'fx-ui';
import Loading from 'app/shared/Loading/Loading';
import OverviewList from './overview-list.component';
import { useRoot } from 'app/core/root/root';

interface OverviewPageProps {
  freightRequests: FreightRequestInterface[];
  loading: boolean;
  subscribeToChanges: () => void;
  refreshFreightRequests: () => void;
}

interface StyledSidebarProps {
  showSidebar: boolean;
}

const StyledLogoImg = styled.img`
  width: 150px;
  transform: translateX(-3px);
`;

const StyledSidebarContainer = styled.aside<StyledSidebarProps>`
  position: fixed;
  display: grid;
  grid-template: auto 1fr auto / 100%;
  background-color: ${({ theme }) => theme.backgroundColor};
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: transform 0.25s ease
    ${({ showSidebar }) => (showSidebar ? '' : ', opacity 0.25s ease')};
  transform: translateX(-${({ showSidebar }) => (showSidebar ? 100 : 0)}%);
  left: 100%;
  z-index: 100;
  opacity: ${({ showSidebar }) => (showSidebar ? 1 : 0)};
  max-width: 500px;

  header {
    .toolbar-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .sidebar-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  .sidebar-footer {
    background-color: ${({ theme }) => theme.backgroundColor};
  }
`;

const StyledPageContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template: auto 1fr / 100%;

  .notification {
    display: none;
  }

  @media screen and (min-width: 768px) {
    .notification {
      display: block;
      color: black;
      margin: 20px;
      background: #23b1ec;
      padding: 30px;
      border-radius: 10px;
      font-size: 20px;
      letter-spacing: 1px;
      text-align: center;
      a {
        font-weight: 600;
        text-decoration: underline;
        color: black;
      }
    }
  }

  .fr-overview-page-content {
    overflow-x: hidden;
    height: calc(100vh - 65px);
    display: grid;
    grid-template: auto 1fr / 100%;

    .overview-page-title {
      padding-left: 1em;
      margin-bottom: 27px;
    }
  }
`;

const OverviewPage: React.FC<OverviewPageProps> = ({
  freightRequests,
  loading,
  subscribeToChanges,
  refreshFreightRequests,
}) => {
  const { t } = useTranslation();
  const {
    logout,
    fetchUser,
    currentUser: { username },
  } = useRoot();
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    subscribeToChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StyledSidebarContainer showSidebar={showSidebar}>
        <header>
          <Toolbar
            className="sidebar-toolbar"
            closeAction={() => setShowSidebar(false)}>
            <Item className="toolbar-content">
              <Title subtitle>{t('Logged in as')}</Title>
              <Label>{username}</Label>
            </Item>
          </Toolbar>
        </header>
        <div className="sidebar-content">
          <Button onTouchStart={logout}>Log out</Button>
        </div>
        <Footer className="sidebar-footer">
          <Label small>FreiXchange v2.0.0-beta.23</Label>
        </Footer>
      </StyledSidebarContainer>

      <StyledPageContainer>
        <Toolbar
          menuAction={() => setShowSidebar(true)}
          refreshAction={() => {
            refreshFreightRequests();
            fetchUser();
          }}
          refreshLoading={loading}>
          <StyledLogoImg src={logo} alt="logo" />
        </Toolbar>
        <div className="fr-overview-page-content">
          <div className="notification">
            For the full desktop experience{' '}
            <a href="https://app.freixchange.com">click here</a>
          </div>
          <Title className="overview-page-title">{t('Cargoes')}</Title>
          {loading ? (
            <Loading />
          ) : (
            <OverviewList freightRequests={freightRequests} />
          )}
        </div>
      </StyledPageContainer>
    </>
  );
};

export default OverviewPage;
