import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';

// Types
import { DiscussionItem } from 'app/freight-request/models/freight-request.models';

// Components
import { ChatMessage, ChatNotification, DateLabel } from 'fx-ui';
import moment from 'moment';
import { useRoot } from 'app/core/root/root';

interface FixtureLogDiscussionListProps {
  discussion?: DiscussionItem[];
}

const StyeldContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  padding-bottom: 75px;

  .date-break-label {
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
    align-self: center;
    margin-top: 16px;
    margin-bottom: 30px;

    & + .sc-kEYyzF {
      margin-top: 0;
    }
  }
`;

const FixtureLogDiscussionList: React.FC<FixtureLogDiscussionListProps> = ({
  discussion = [],
}) => {
  const { currentUser, fetchUser } = useRoot();
  const [currentUserId, setCurrentUserId] = useState('');
  let lastElement: HTMLDivElement;

  useEffect(() => {
    if (lastElement) {
      lastElement.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discussion]);

  useEffect(() => {
    const { userId } = currentUser;
    if (userId) {
      setCurrentUserId(userId);
    } else {
      fetchUser();
    }
  }, [currentUser, fetchUser]);

  return (
    <StyeldContainer>
      {discussion.map(
        ({ value, created, type, user: { userId, firstName } }, idx, arr) => {
          const isMe = userId === currentUserId;

          const showDateBreak =
            idx === 0 ||
            (idx > 0 && !moment(arr[idx - 1].created).isSame(created, 'day'));

          return (
            <Fragment key={`chat-message-${idx}-${value}`}>
              {type === 'message' ? (
                <>
                  {showDateBreak && (
                    <DateLabel
                      date={created}
                      format="chat"
                      className="date-break-label"
                    />
                  )}
                  <ChatMessage
                    key={`message-${idx}`}
                    isOutgoing={isMe}
                    sender={firstName}
                    dateSent={created}>
                    {value}
                  </ChatMessage>
                </>
              ) : (
                <ChatNotification date={created} action={value} />
              )}
              <div
                ref={el => {
                  if (el) {
                    lastElement = el;
                  }
                }}
              />
            </Fragment>
          );
        }
      )}
    </StyeldContainer>
  );
};

export default FixtureLogDiscussionList;
