export enum FreightRequestStatus {
  /** New/Unread freight (bid) request */
  UNREAD = 'requested',
  /** Freight request is read */
  READ = 'read',
  /** Intent to offer  */
  DRAFTING = 'draft',
  /** Offer/Indication submitted */
  SUBMITTED = 'submitted',
  /** Offer needs confirmation */
  OUTDATED = 'confirm',
  /** Offer is withdrawn */
  WITHDRAWN = 'withdrawn',
  /** Offer has been approved / subs lifted  */
  COMPLETED = 'closed',
  /** Offer has been rejected */
  REJECTED = 'declined',
  /** TODO: Find explaination for firm */
  FIRM = 'firm',
}

export type Status =
  | FreightRequestStatus.UNREAD
  | FreightRequestStatus.DRAFTING
  | FreightRequestStatus.SUBMITTED
  | FreightRequestStatus.COMPLETED
  | FreightRequestStatus.OUTDATED
  | FreightRequestStatus.REJECTED
  | FreightRequestStatus.FIRM;

export interface ShippingLocation {
  locationName: string;
  locationCode: string;
}

export interface Quantity {
  unit: string;
  amount: number;
  tolerance: string;
  customTolerance: string;
}

export interface Company {
  companyId: string;
  companyName: string;
}

export interface Cargo {
  cargoName: string;
  cargoType: string;
  quantity: Quantity;
}

export interface ShippingOffer {
  __typename?: string;
  offerAmount?: string;
  offerUnit?: string;
  offerComment?: string;
}

export interface ShippingRoute {
  __typename?: string;
  routeId: string;
  addId: string;
  loadLocations: ShippingLocation[];
  dischargeLocations: ShippingLocation[];
  loadComment: string;
  dischargeComment: string;
  offer: ShippingOffer;
}

export interface Ship {
  id: string;
  name: string;
  laytimeHours?: number;
  demurrageRate?: number;
  overage?: number;
  comments?: string;
}

export interface FreightRequestDetails {
  layDate: Date;
  cancelling?: Date;
  offerWithin?: Date;
  offerValidTo?: Date;
  offerSubsDue?: Date;
}

export interface FreightRequestInterface {
  freightRequestId: string;
  details: FreightRequestDetails;
  company: Company;
  cargo: Cargo;
  status: FreightRequestStatus;
  routes: ShippingRoute[];
  ship?: Ship;
  conditions: string[];
  subTerms: SubTermInterface[];
  requirements: RequirementInterface[];
  documents: Document[];
  discussion: DiscussionItem[];
  unreadMessages: number;
  modified: Date;
  created: Date;
}

export interface DocumentDetail {
  reference: string;
  description: string;
}
export interface SubTermInterface {
  description: string;
  amendments: DocumentDetail[];
}
export interface RequirementInterface {
  subject: string;
  type: string;
  details: DocumentDetail[];
}

export interface Document {
  documentName: string;
  documentUrl: string;
}

export interface DiscussionItem {
  created: Date;
  value: string;
  type: DiscussionItemType;
  user: User;
  fixtureId?: string;
}

export type DiscussionItemType = 'message' | 'notification';

export interface User {
  userId: string;
  firstName: string;
  lastName: string;
}
