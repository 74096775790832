import React, { Fragment } from 'react';
import {
  Card,
  CardContent,
  Item,
  Status,
  DateLabel,
  Route,
  Title,
  Label,
  Stepper,
  Rate,
} from 'fx-ui';
import OverviewHeader from '../overview/overview-header.component';
import styled from 'styled-components';
import { FreightRequestInterface } from 'app/freight-request/models/freight-request.models';
import { useTranslation } from 'react-i18next';

interface FreightRequestDetailsInfoProps {
  freightRequest: FreightRequestInterface;
}

const StyledCard = styled(Card)`
  margin: 0 20px 25px;

  .card-content {
    padding: 15px 15px 10px;
    .date-created-label {
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
    }
    .ship-name,
    .date-label {
      font-size: 14px;
    }
    .ship-name {
      margin-bottom: 4px;
    }
    .step-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 19px;
    }
    .comments {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 15px;
    }
  }
`;

const FreightRequestDetailsInfo: React.FC<FreightRequestDetailsInfoProps> = ({
  freightRequest,
}) => {
  const {
    company: { companyName },
    details: { layDate, cancelling },
    status,
    cargo,
    routes,
    ship,
    created,
  } = freightRequest;
  const { t } = useTranslation();
  const shipName = ship ? ship.name : '';
  return (
    <StyledCard>
      <CardContent className="card-content">
        <Item>
          <Status status={status} />
          <DateLabel
            className="date-created-label"
            date={created}
            format="smart"
          />
        </Item>

        <OverviewHeader companyName={companyName} cargo={cargo} />

        <Item>
          <DateLabel
            date={[layDate, cancelling]}
            format="short"
            className="date-label"
          />
        </Item>

        <Stepper>
          {routes.map((route, idx) => {
            const {
              routeId,
              loadComment,
              dischargeComment,
              offer: { offerComment, offerAmount, offerUnit },
            } = route;
            const comments = [
              { label: t('Load'), comment: loadComment },
              { label: t('Discharge'), comment: dischargeComment },
              { label: t('My comment'), comment: offerComment },
            ].filter(({ comment }) => !!comment);

            return (
              <Fragment key={`route-step-${routeId}`}>
                <div className="step-container">
                  <div>
                    {shipName && (
                      <Label className="ship-name">{shipName}</Label>
                    )}

                    <Route {...route} />
                  </div>
                  <div>
                    <Rate offerAmount={offerAmount} offerUnit={offerUnit} />
                  </div>
                </div>

                {comments.map(({ label, comment }) => (
                  <Item
                    key={`route-${routeId}-comment-${label}`}
                    className="comments">
                    <Title subtitle>{label}</Title>
                    <Label italic small>
                      {comment}
                    </Label>
                  </Item>
                ))}
              </Fragment>
            );
          })}
        </Stepper>
      </CardContent>
    </StyledCard>
  );
};

export default FreightRequestDetailsInfo;
