import gql from 'graphql-tag';

export const GET_FIXTURE_LOG = gql`
  query($freightRequestId: ID!, $readFixtureLog: Boolean) {
    freightRequest: getFreightRequest(
      id: $freightRequestId
      readFixtureLog: $readFixtureLog
    ) {
      company {
        companyName
      }
      cargo {
        cargoName
        quantity {
          amount
          unit
        }
      }
      discussion {
        value
        created
        user {
          userId
          firstName
          lastName
        }
        type
      }
    }
  }
`;
