import React, { useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import { ShippingRoute } from 'app/freight-request/models/freight-request.models';
import {
  Card,
  CardContent,
  Route,
  Title,
  Input,
  Item,
  DateLabel,
  Textarea,
  SelectUnit,
} from 'fx-ui';

export interface UpdateRouteInterface {
  routeId: string;
  __typename?: string;
  offerAmount?: string;
  offerUnit?: string;
  offerComment?: string;
}

interface IndicateOfferRouteProps {
  route: ShippingRoute;
  date: Date[] | (Date | undefined)[];
  updateRoute: (route: UpdateRouteInterface) => void;
}

const StyledCard = styled(Card)`
  .route-input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
  }

  .route-container {
    margin-top: 10px;
    margin-bottom: 47px;
  }

  input {
    margin-right: 10px;
  }
`;

const IndicateOfferRoute: React.FC<IndicateOfferRouteProps> = ({
  route,
  date,
  updateRoute,
}) => {
  const {
    routeId,
    offer: { offerAmount, offerUnit = 'ws', offerComment = '' },
  } = route;
  const [amount, setAmount] = useState(offerAmount);
  const [unit, setUnit] = useState(offerUnit);
  const [comment, setComment] = useState(offerComment);

  const handleInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {
      target: {
        value,
        name,
        validity: { valid },
      },
    } = event;

    if (valid) {
      let updateValue: string | number = '';
      switch (name) {
        case 'offerAmount':
          updateValue = value;
          setAmount(value);
          break;
        case 'offerComment':
          updateValue = value;
          setComment(value);
          break;
        case 'offerUnit':
          updateValue = value;
          setUnit(value);
          break;

        default:
          console.error(`${name} is not a valid update field`);
          break;
      }
      updateRoute({
        routeId,
        offerAmount: amount,
        offerUnit: unit,
        offerComment: comment,
        [name]: updateValue,
      });
    }
  };

  return (
    <StyledCard>
      <CardContent>
        <div className="route-container">
          <Route {...route} />
          <DateLabel date={date} format="short" />
        </div>
        <div className="route-input-container">
          <Title subtitle>Your rate</Title>
          <Item flex="start">
            <Input
              value={offerAmount}
              pattern="^[0-9.,]+$"
              name="offerAmount"
              onChange={handleInput}
            />
            <SelectUnit offerUnit={offerUnit} updateUnit={handleInput} />
          </Item>
        </div>
        <div className="route-input-container">
          <Title subtitle>Your route comment</Title>
          <Textarea
            value={comment}
            name="offerComment"
            onChange={handleInput}
          />
        </div>
      </CardContent>
    </StyledCard>
  );
};

export default IndicateOfferRoute;
