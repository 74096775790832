import React from 'react';
import { Title, Input, Button } from 'fx-ui';
import styled from 'styled-components';
import { RouteComponentProps, Link } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { useRoot } from 'app/core/root/root';
import { useFormik } from 'formik';

interface LoginPageProps {
  clearEmail: () => void;
  email?: string | null;
}

const StyledContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  .has-errors {
    color: ${({ theme }) => theme.palette.danger};
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 320px;
    margin-top: 40px;
    margin-bottom: 10px;
    label {
      margin-bottom: 10px;
    }
    input {
      margin-bottom: 10px;
    }
  }

  .forgot-password {
    margin-top: 20px;
    margin-bottom: 30px;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.palette.medium};
  }
`;

const LoginPage: React.FC<RouteComponentProps<LoginPageProps>> = ({
  clearEmail,
  email: storedEmail,
}) => {
  const { t } = useTranslation();
  const { login, error } = useRoot();
  const formik = useFormik({
    initialValues: {
      email: storedEmail || '',
      password: '',
    },
    onSubmit: ({ email, password }) => login({ email, password }),
  });

  return (
    <StyledContainer onSubmit={formik.handleSubmit}>
      <Title subtitle>{t(storedEmail ? `Welcome back` : 'Welcome')}</Title>
      {storedEmail && (
        <>
          <Title>{storedEmail}</Title>
          <Title subtitle onTouchStart={clearEmail}>
            {t('change user')}
          </Title>
        </>
      )}
      {error && <p className="has-errors">{error}</p>}
      <div className="input-wrapper">
        {!storedEmail && (
          <>
            <Title subtitle>{t('your email')}</Title>
            <Input
              name="email"
              placeholder="your email"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </>
        )}
        <Title subtitle>{t('your password')}</Title>
        <Input
          name="password"
          placeholder="type a new password"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.password}
        />
      </div>
      <Link className="forgot-password" to="/reset-password">
        <Title subtitle>{t('i forgot my password')}</Title>
      </Link>
      <Button type="submit" size="large">
        LOG IN
      </Button>
    </StyledContainer>
  );
};

export default LoginPage;
