import React from 'react';
import styled from 'styled-components';

const StyledCardContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 20px;
  color: ${({ theme }) => theme.palette.white};
`;

const CardContent: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => {
  return <StyledCardContent {...props}>{children}</StyledCardContent>;
};

export default CardContent;
