import React, { useState } from 'react';
import { Card, CardContent, Title, Item, Label, Icon } from 'fx-ui';
import { RequirementInterface } from 'app/freight-request/models/freight-request.models';
import styled from 'styled-components';
import { revealKeyframes, hideKeyframes } from 'fx-ui/theme';

interface FreightRequestDetailsRequirementsProps {
  requirements: RequirementInterface[];
}

interface ListItemProps {
  reference: string;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5em 0;

  .details-list {
    position: relative;
    max-height: 0;
    margin-block-end: 0;
    animation: ${hideKeyframes} 0.3s ease;
    animation-fill-mode: forwards;
    overflow: hidden;
  }

  &.selectedRow {
    .details-list {
      animation: ${revealKeyframes} 0.5s ease;
      animation-fill-mode: forwards;
    }
  }
  .sub-title {
    margin-bottom: 6px;
  }
  .list-header {
    label {
      font-size: 15px;
    }
  }

  .list-item {
    font-size: 14px;
    line-height: 19px;
    &:first-of-type {
      margin-top: 15px;
    }
    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }
`;

const StyledTitle = styled(Title)`
  margin-bottom: 8px;
`;

const StyledItem = styled(Item)`
  padding: 0;
`;

const StyledList = styled.ul`
  margin-top: 0;
  padding: 0 1em;
  list-style-type: none;
`;

const StyledListItem = styled.li<ListItemProps>`
  &:before {
    content: '${({ reference }) => reference}';
    color: ${({ theme }) => theme.palette.white};
    position: absolute;
    margin-left: -1em;
  }
`;

const FreightRequestDetailsRequirements: React.FC<
  FreightRequestDetailsRequirementsProps
> = ({ requirements = [] }) => {
  const [openedDetailIdx, setOpenedDetailIdx] = useState<number | null>(null);
  const { length: amountRequirements } = requirements;

  if (!(amountRequirements > 0)) {
    return null;
  }

  return (
    <Card>
      <CardContent>
        <StyledTitle className="card-header" subtitle>
          Requirements ({amountRequirements})
        </StyledTitle>
        {requirements.map(({ type, subject, details }, idx) => {
          const isSelected = openedDetailIdx === idx;
          return (
            <StyledContainer
              key={`requirement-${subject}`}
              className={isSelected ? 'selectedRow' : ''}>
              <StyledTitle
                className="sub-title"
                subtitle
                onClick={() => setOpenedDetailIdx(isSelected ? null : idx)}>
                {type} requirement
              </StyledTitle>
              <StyledItem
                className="list-header"
                onClick={() => setOpenedDetailIdx(isSelected ? null : idx)}>
                <Label>Subject: {subject}</Label>
                <Icon name="caret" fill="white" rotate={isSelected ? 180 : 0} />
              </StyledItem>
              <StyledList className="details-list">
                {details.map(detail => (
                  <StyledListItem
                    className="list-item"
                    reference={detail.reference}
                    key={`requirement-detail-${detail.reference}`}>
                    {detail.description}
                  </StyledListItem>
                ))}
              </StyledList>
            </StyledContainer>
          );
        })}
      </CardContent>
    </Card>
  );
};

export default FreightRequestDetailsRequirements;
