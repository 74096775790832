import React, {
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  TouchEvent,
} from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import {
  FreightRequestInterface,
  ShippingRoute,
} from 'app/freight-request/models/freight-request.models';
import IndicateOfferRoute, {
  UpdateRouteInterface,
} from './indicate-offer-route.component';
import { Input, Title, Toolbar, Icon, Textarea, Footer, Button } from 'fx-ui';
import { useIndicateOfferContext } from 'app/freight-request/containers/indicate-offer.container';
import { StylesConfig } from 'react-select/src/styles';
import { ValueType, ActionMeta } from 'react-select/src/types';
import { MasterShip } from 'app/freight-request/models/master-ships.models';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';

interface IndicateOfferPageProps {
  freightRequest: FreightRequestInterface;
}

const StyledPageContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template: auto 1fr auto / 100%;

  .indicate-offer-page-content {
    overflow-x: hidden;
    height: 100%;
  }
`;

const StyledMessageIcon = styled(Icon)`
  margin-left: 10px;
`;

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 17px;
  label {
    margin-bottom: 10px;
  }
  &:last-child {
    margin-bottom: 35px;
  }
`;

const customSelectStyle: StylesConfig = {
  input: provided => ({
    ...provided,
    color: '#fff',
  }),
  singleValue: provided => ({
    ...provided,
    color: '#fff',
  }),
  control: provided => ({
    ...provided,
    backgroundColor: '#383838',
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: '#383838',
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    color: isFocused ? '#000' : '#fff',
  }),
};

const StyledPageTitle = styled(Title)`
  padding: 26px 16px 28px;
`;

const StyledForm = styled.form`
  padding: 0 20px;
`;

const confirmNavigation = (isDrafting: any) => {
  if (isDrafting) {
    return window.confirm(
      'You have unsaved changes. Are you sure you want to leave?'
    );
  }
  return true;
};

const handleBackAction = (isDrafting: any) => {
  if (confirmNavigation(isDrafting)) {
    navigate('/');
  }
};

const IndicateOfferPage: React.FC<IndicateOfferPageProps> = ({
  freightRequest: {
    freightRequestId,
    details: { cancelling, layDate },
    routes: freightRequestRoutes = [],
    ship: freightRequestShip,
  },
}) => {
  const { t } = useTranslation();
  const {
    masterShips,
    filterMasterShips,
    upsertOffer,
    loadingMasterShips,
  } = useIndicateOfferContext();
  const [selectedMasterShip, setSelectedMasterShip] = useState<
    MasterShip | undefined
  >(
    (freightRequestShip &&
      freightRequestShip.id &&
      freightRequestShip.name && {
        id: freightRequestShip.id,
        name: freightRequestShip.name,
      }) ||
      undefined
  );
  const [isDrafting, setIsDrafting] = useState<boolean>(false);
  const [shipId, setShipId] = useState<string>('');
  const [shipName, setShipName] = useState('');
  const [laytime, setLaytime] = useState<number | undefined>();
  const [demurrage, setDemurrage] = useState<number | undefined>();
  const [overage, setOverage] = useState<number | undefined>();
  const [shipComment, setShipComment] = useState<string>();
  const [routes, setRoutes] = useState<ShippingRoute[]>(freightRequestRoutes);

  useEffect(() => {
    setRoutes(freightRequestRoutes);
  }, [freightRequestRoutes]);

  useEffect(() => {
    if (freightRequestShip) {
      const {
        id,
        name,
        laytimeHours,
        demurrageRate,
        overage: overageRate,
        comments = '',
      } = freightRequestShip;
      setShipId(id);
      setShipName(name);
      setLaytime(laytimeHours);
      setDemurrage(demurrageRate);
      setOverage(overageRate);
      setShipComment(comments);
    }
  }, [freightRequestShip]);

  useEffect(() => {
    if (shipId) {
      setSelectedMasterShip(masterShips.find(({ id }) => id === shipId));
    }
  }, [shipId, masterShips]);

  const handleNoOptionMessage = ({
    inputValue,
  }: {
    inputValue: string;
  }): string | null => {
    if (loadingMasterShips) {
      return 'Searching...';
    }

    if (typeof inputValue === 'string') {
      if (inputValue.length < 3) {
        return 'Type to search';
      }
    } else {
      if (inputValue < 100) {
        return 'Type to search';
      }
    }

    return `Couldn't find ships named ${inputValue}`;
  };

  const handleShipInputChange = (
    value: ValueType<MasterShip>,
    { action }: ActionMeta
  ) => {
    if (value && action === 'select-option') {
      // const { id } = value;
      const { id, name } = (value as unknown) as MasterShip;
      setShipId(id);
      setShipName(name);
      setIsDrafting(true);
    }
  };

  const handleUpdateRoute = ({
    routeId: id,
    offerAmount,
    offerComment,
    offerUnit,
  }: UpdateRouteInterface) => {
    setRoutes(
      routes.map(
        ({ __typename, ...route }): ShippingRoute => {
          if (route.routeId === id) {
            return {
              ...route,
              offer: {
                offerUnit,
                offerComment,
                offerAmount,
              },
            };
          }
          return route;
        }
      )
    );
    setIsDrafting(true);
  };

  const handleSubmit = (event: TouchEvent<HTMLButtonElement>) => {
    if (event) {
      const ship = shipId
        ? {
            id: shipId,
            name: shipName,
            laytimeHours: laytime,
            demurrageRate: demurrage,
            overage,
            comments: shipComment,
          }
        : undefined;
      const offers = routes.map(
        ({
          routeId,
          addId,
          loadLocations,
          dischargeLocations,
          loadComment,
          dischargeComment,
          offer: { __typename, ...offer },
        }) => ({
          routeId,
          addId,
          loadLocations: loadLocations.map(
            ({ locationName, locationCode }) => ({
              locationName,
              locationCode,
            })
          ),
          dischargeLocations: dischargeLocations.map(
            ({ locationName, locationCode }) => ({
              locationName,
              locationCode,
            })
          ),
          loadComment,
          dischargeComment,
          offer,
        })
      );
      upsertOffer(offers, ship);
      navigate(`/${freightRequestId}?openFixtureLog=true`);
    }
  };

  const offerDetails: {
    name: string;
    value: number | undefined;
    action: Dispatch<SetStateAction<number | undefined>>;
  }[] = [
    {
      name: 'laytime',
      value: laytime,
      action: setLaytime,
    },
    {
      name: 'demurrage',
      value: demurrage,
      action: setDemurrage,
    },
    {
      name: 'overage',
      value: overage,
      action: setOverage,
    },
  ];
  return (
    <StyledPageContainer>
      <Toolbar backAction={() => handleBackAction(isDrafting)}>
        <Title subtitle>Fixture log</Title>
        <StyledMessageIcon name="message" fill="white" />
      </Toolbar>
      <div className="indicate-offer-page-content">
        <StyledPageTitle>Cargo details</StyledPageTitle>
        <StyledForm>
          <StyledInputContainer>
            <Title subtitle>Ship</Title>
            <Select
              styles={customSelectStyle}
              defaultValue={selectedMasterShip}
              options={masterShips}
              noOptionsMessage={handleNoOptionMessage}
              onInputChange={value => {
                filterMasterShips(value);
              }}
              name="masterShip"
              getOptionValue={({ id }) => id}
              getOptionLabel={({ name }) => name}
              onChange={handleShipInputChange}
              isLoading={loadingMasterShips}
            />
          </StyledInputContainer>
          {offerDetails.map(({ name, value, action }) => (
            <StyledInputContainer key={`input-${name}`}>
              <Title subtitle>{name}</Title>
              <Input
                name={name}
                value={value || ''}
                onChange={({ target: { value: newValue } }) => {
                  action(Number(newValue));
                  setIsDrafting(true);
                }}
              />
            </StyledInputContainer>
          ))}
          <StyledInputContainer>
            <Title subtitle>Your ship comment</Title>
            <Textarea
              value={shipComment}
              onChange={({ target: { value } }) => {
                setShipComment(value);
                setIsDrafting(true);
              }}
            />
          </StyledInputContainer>
        </StyledForm>
        {routes.map((route, idx) => (
          <IndicateOfferRoute
            key={`route-${route.routeId}`}
            route={route}
            date={[layDate, cancelling]}
            updateRoute={handleUpdateRoute}
          />
        ))}
        <Footer>
          <Button onTouchStart={handleSubmit}>{t('Submit offer')}</Button>
        </Footer>
      </div>
    </StyledPageContainer>
  );
};

export default IndicateOfferPage;
