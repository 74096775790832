import React, { FC } from 'react';
import { Title, Input, Button } from 'fx-ui';
import styled from 'styled-components';
import ErrorContainer from 'app/shared/Error/ErrorContainer';
import { RouteComponentProps, navigate } from '@reach/router';
import { useRoot } from 'app/core/root/root';
import { useFormik } from 'formik';
import * as yup from 'yup';

interface RegisterPageProps {
  email?: string | null;
  token: string;
}

const StyledContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .second-time-login-header {
    margin-bottom: 40px;

    label {
      justify-content: center;
    }
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-bottom: 10px;

    .has-errors {
      color: ${({ theme }) => theme.palette.danger};
    }
  }

  .switch-account {
    margin-top: 27px;
    margin-bottom: 35px;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.palette.medium};
  }
`;

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .label('Password')
    .required()
    .min(6, 'Password must contain at least 6 characters'),
  confirmPassword: yup
    .string()
    .required()
    .label('Confirm password')
    .test('passwords-match', 'Passwords must match', function(value) {
      return this.parent.password === value;
    }),
});

const RegisterPage: FC<RouteComponentProps<RegisterPageProps>> = ({
  email,
  token = '',
}) => {
  const { resetPassword } = useRoot();
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    onSubmit: ({ password }) => {
      resetPassword(token, password);
      navigate('/');
    },
    validationSchema,
  });

  if (!token) {
    return (
      <ErrorContainer
        title="Invalid token"
        message="Your token is invalid. Please request a new token."
      />
    );
  }

  return (
    <StyledContainer onSubmit={formik.handleSubmit}>
      <div className="second-time-login-header">
        <Title subtitle>create new password</Title>
        {email && <Title subtitle>for {email}</Title>}
      </div>
      <div className="input-wrapper">
        <Title subtitle>new password</Title>
        <Input
          name="password"
          placeholder="type a new password"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.password}
        />
        <p className="has-errors">
          {!!formik.values.password &&
            !!formik.errors.password &&
            formik.errors.password}
        </p>
      </div>
      <div className="input-wrapper">
        <Title subtitle>confirm password</Title>
        <Input
          name="confirmPassword"
          placeholder="re-type same password"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.confirmPassword}
        />
        <p className="has-errors">
          {!!formik.values.confirmPassword &&
            !!formik.errors.confirmPassword &&
            formik.errors.confirmPassword}
        </p>
      </div>
      <a className="switch-account" href="/">
        <Title subtitle>log in with a different account</Title>
      </a>
      <Button size="large">set new password</Button>
    </StyledContainer>
  );
};

export default RegisterPage;
