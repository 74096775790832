import gql from 'graphql-tag';

export const FREIGHT_REQUEST_STATUS_MUTATION = gql`
  mutation updateStatus(
    $freightRequestId: ID!
    $status: String!
    $statusReason: String
    $statusComment: String
  ) {
    updateFreightRequestStatus(
      freightRequestId: $freightRequestId
      status: $status
      statusReason: $statusReason
      statusComment: $statusComment
    )
  }
`;
