import React from 'react';
import styled from 'styled-components';

interface ContainerProps {
  column?: boolean;
}

interface StyledContainerProps {
  column?: boolean;
}

const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  flex-direction: ${({ column = false }) => (column ? 'column' : 'row')};
  justify-content: ${({ column = false }) =>
    column ? 'center' : 'flex-start'};
  align-items: ${({ column = false }) => (column ? 'none' : 'center')};
  max-width: 100%;
`;

const Container: React.FC<ContainerProps> = ({ children, ...props }) => {
  return <StyledContainer {...props}>{children}</StyledContainer>;
};

export default Container;
