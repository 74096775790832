import gql from 'graphql-tag';

export const UPSERT_FREIGHT_REQUEST = gql`
  mutation upsertFreightRequest($input: UpsertFreightRequest!) {
    upsertFreightRequest(freightRequest: $input) {
      freightRequestId
      status
    }
  }
`;
