import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Accounts from './config/apollo';
import { Root } from 'app/core/root';
import * as serviceWorker from './serviceWorker';

import './index.css';
import './config/i18n';
import Loading from 'app/shared/Loading/Loading';
import { ApolloProvider } from '@apollo/react-hooks';

ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <ApolloProvider client={Accounts.apolloClient}>
      <Root />
    </ApolloProvider>
  </Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
