import React from 'react';
import styled from 'styled-components';
import { Label } from '../Label';
import { Title } from '../Title';

interface RateProps {
  offerAmount?: string;
  offerUnit?: string;
}

/** Wordscale Container */
const RateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.baseBorderColor};
  padding: 0.5em;
  box-sizing: border-box;
`;

const StyledLabel = styled(Label)`
  font-weight: 700;
`;

const StyledTitle = styled(Title)`
  margin: 0;
`;

const Rate: React.FC<RateProps> = ({ offerAmount, offerUnit, ...props }) => {
  if (!offerAmount) {
    return null;
  }

  return (
    <RateContainer {...props}>
      {offerAmount && <StyledLabel centered>{offerAmount}</StyledLabel>}
      <StyledTitle subtitle>{offerUnit || 'ws'}</StyledTitle>
    </RateContainer>
  );
};

export default Rate;
