import React, { ReactElement } from 'react';

export const getIconDefinition = (
  name: string
): { icon: ReactElement; viewBox: string } => {
  switch (name) {
    case 'route': {
      return {
        icon: (
          <g>
            <g>
              <circle r="4" cy="4" cx="4" vectorEffect="non-scaling-stroke" />
            </g>
            <g>
              <circle r="4" cy="27" cx="4" vectorEffect="non-scaling-stroke" />
            </g>
            <rect
              height="22"
              width="1"
              y="5"
              x="3.5"
              vectorEffect="non-scaling-stroke"
            />
          </g>
        ),
        viewBox: '0 0 24 33',
      };
    }

    case 'arrow': {
      return {
        icon: (
          <path
            d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
            vectorEffect="non-scaling-stroke"
          />
        ),
        viewBox: '0 0 24 24',
      };
    }

    case 'edit': {
      return {
        icon: (
          <path
            d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
            vectorEffect="non-scaling-stroke"
          />
        ),
        viewBox: '0 0 24 24',
      };
    }

    case 'time': {
      return {
        icon: (
          <g>
            <path
              d="M90.4,6H21.9C13.7,6,7,12.7,7,20.9V43c0,27.1,37,57,49,57s49-29.9,49-57l0.3-21.9C105.4,12.8,98.7,6,90.4,6z   M87.8,44.5c0,17.5-24,36.9-31.8,36.9c-7.8,0-31.8-19.4-31.8-36.9V28c0-3,2.4-5.4,5.4-5.4h52.9c3,0,5.5,2.5,5.4,5.5L87.8,44.5z"
              vectorEffect="non-scaling-stroke"
            />
            <polygon
              points="71,88 44,88 21,49 95,49"
              vectorEffect="non-scaling-stroke"
            />
            <path
              d="M56.3,90c-12,0-49,29.9-49,57L7,168.9c-0.1,8.3,6.6,15.1,14.9,15.1h68.5c8.2,0,14.9-6.7,14.9-14.9V147  C105.3,119.9,68.3,90,56.3,90z M88.1,163c0,3-2.4,5.4-5.4,5.4H29.7c-3,0-5.5-2.5-5.4-5.5l0.2-16.5c0-17.5,24-36.9,31.8-36.9  c7.8,0,31.8,19.4,31.8,36.9V163z"
              vectorEffect="non-scaling-stroke"
            />
            <rect
              height="70"
              width="14"
              y="100"
              x="49"
              vectorEffect="non-scaling-stroke"
            />
            <polygon
              points="49,152 63,152 88,170 24,170"
              vectorEffect="non-scaling-stroke"
            />
          </g>
        ),
        viewBox: '0 0 114 192',
      };
    }

    case 'message': {
      return {
        icon: (
          <path
            d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-2 12H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z"
            vectorEffect="non-scaling-stroke"
          />
        ),
        viewBox: '0 0 24 24',
      };
    }

    case 'refresh': {
      return {
        icon: (
          <path d="m255.999994,411.625001c-85.590211,0 -155.624996,-70.037143 -155.624996,-155.624996c0,-85.594927 70.034785,-155.624996 155.624996,-155.624996c42.796874,0 81.701944,18.156249 108.944571,46.688678l-83.007071,82.998819l181.562495,0l0,-181.562495l-60.950765,60.955481c-37.604658,-37.61409 -89.479657,-60.955481 -146.549229,-60.955481c-114.12146,0 -207.499994,93.378534 -207.499994,207.499994s92.071048,207.499994 207.499994,207.499994c95.55021,0 174.826998,-63.822754 199.719923,-150.909087l-54.882569,0c-22.099928,58.431291 -79.154174,99.034088 -144.837354,99.034088z" />
        ),
        viewBox: '0 0 512 512',
      };
    }

    case 'attachment': {
      return {
        icon: (
          <path
            d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"
            vectorEffect="non-scaling-stroke"
          />
        ),
        viewBox: '0 0 24 24',
      };
    }

    case 'caret': {
      return {
        icon: (
          <path
            d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"
            vectorEffect="non-scaling-stroke"
          />
        ),
        viewBox: '0 0 24 24',
      };
    }

    case 'send': {
      return {
        icon: (
          <g>
            <g>
              <polygon points="8,7.6 8,15.5 25.3,18 8,20.5 8,28.4 32.3,18" />
            </g>
            <g>
              <path d="M17.8,35.7C8,35.7,0,27.7,0,17.9S8,0.1,17.8,0.1c9.8,0,17.8,8,17.8,17.8S27.6,35.7,17.8,35.7z M17.8,1.7 C8.9,1.7,1.6,9,1.6,17.9s7.3,16.2,16.2,16.2c8.9,0,16.2-7.3,16.2-16.2S26.7,1.7,17.8,1.7z" />
            </g>
          </g>
        ),
        viewBox: '0 0 35.7 35.8',
      };
    }

    case 'menu': {
      return {
        icon: (
          <g>
            <path
              d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"
              vectorEffect="non-scaling-stroke"
            />
          </g>
        ),
        viewBox: '0 0 24 24',
      };
    }

    case 'person': {
      return {
        icon: (
          <path
            d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
            vectorEffect="non-scaling-stroke"
          />
        ),
        viewBox: '0 0 24 24',
      };
    }

    case 'close': {
      return {
        icon: (
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            vectorEffect="non-scaling-stroke"
          />
        ),
        viewBox: '0 0 24 24',
      };
    }

    case 'tiles': {
      return {
        icon: (
          <path
            d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"
            vectorEffect="non-scaling-stroke"
          />
        ),
        viewBox: '0 0 24 24',
      };
    }

    default:
      return { icon: <path></path>, viewBox: '' };
  }
};
