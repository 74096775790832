import React from 'react';
import { Card, CardContent, Item, Title, DateLabel } from 'fx-ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface FreightRequestDetailsDeadlinesProps {
  offerWithin?: Date;
  offerValidTo?: Date;
  offerSubsDue?: Date;
}

const StyledCard = styled(Card)`
  .date-label {
    font-size: 14px;
  }
`;

const FreightRequestDetailsDeadlines: React.FC<
  FreightRequestDetailsDeadlinesProps
> = ({ offerWithin, offerValidTo, offerSubsDue }) => {
  const { t } = useTranslation();

  if (!(offerWithin || offerValidTo || offerSubsDue)) {
    return null;
  }

  const dates = [
    { name: 'Offer within', date: offerWithin },
    { name: 'Valid to', date: offerValidTo },
    { name: 'Subs due', date: offerSubsDue },
  ];

  const { length: datesLength } = dates;

  return (
    <StyledCard>
      <CardContent>
        {dates
          .filter(({ date }) => !!date)
          .map(({ name, date }, idx) => {
            return (
              <Item key={`deadline-${name}`} lines={datesLength > idx + 1}>
                <Title subtitle>{t(name)}</Title>
                <DateLabel className="date-label" date={date} format="long" />
              </Item>
            );
          })}
      </CardContent>
    </StyledCard>
  );
};

export default FreightRequestDetailsDeadlines;
