import React, { useState } from 'react';
import styled from 'styled-components';
import { RouteComponentProps, Router } from '@reach/router';
import querystring from 'query-string';
import LoginPage from '../components/login.page';
import RegisterPage from '../components/register.page';
import ForgotPasswordPage from '../components/forgot-password.page';

const StyledRouter = styled(Router)`
  height: 100%;
  width: 100%;
`;

export const AuthContainer: React.FC<RouteComponentProps> = ({
  location: routerLocation = window.location,
}) => {
  const { email: emailArray = '' } = querystring.parse(routerLocation.search);

  const [email, setEmail] = useState(
    !(typeof emailArray === 'string' || emailArray === null)
      ? emailArray.join('')
      : emailArray
  );

  return (
    <StyledRouter>
      <LoginPage
        path="/"
        email={email}
        clearEmail={() => setEmail(null)}
        default
      />
      <RegisterPage path="/register" email={email} />
      <ForgotPasswordPage path="/reset-password" />
      <RegisterPage path="/reset-password/:token" />
    </StyledRouter>
  );
};
