import React from 'react';
import moment from 'moment';
import { Label } from '../Label';
import { LabelProps } from '../Label/Label';

type DateFormat = 'short' | 'default' | 'smart' | 'long' | 'time' | 'chat';

export interface DateLabelProps extends LabelProps {
  date: Date | Date[] | undefined | (Date | undefined)[];
  format?: DateFormat;
}

// const getSmartDate = (date: Date): string => {
//   const alternatives: { range: unitOfTime.StartOf; format: string }[] = [
//     { range: 'day', format: 'HH:mm' },
//     { range: 'week', format: 'dddd HH:mm' },
//     { range: 'year', format: 'DD-MMM HH:mm' },
//   ];

//   const momentDate = moment(date);

//   const { format } = alternatives.find(({ range }) =>
//     momentDate.isSame(moment(), range)
//   ) || { format: 'DD-MMM-YYYY HH:mm' };

//   return momentDate.format(format);
// };

const getChatDate = (date: Date): string => {
  const isSame = moment().isSame(date, 'day');
  const diff = moment()
    .startOf('day')
    .diff(moment(date).startOf('day'), 'day');

  if (isSame) {
    return 'Today';
  }

  return diff === 1 ? 'Yesterday' : moment(date).format('MMMM DD, YYYY');
};

const getDateString = (date: Date, format: DateFormat) =>
  ({
    short: moment(date).format('MMM DD'),
    smart: moment(date).fromNow(),
    long: moment(date).format('MMMM DD, YYYY HH:mm'),
    time: moment(date).format('HH:mm'),
    chat: getChatDate(date),
    default: moment(date).format('MMMM DD, YYYY'),
  }[format]);

const DateLabel: React.FC<DateLabelProps> = ({
  date,
  format = 'default',
  ...props
}) => {
  const dates = Array.isArray(date) ? date : [date];

  return (
    <Label {...props}>
      {dates.map(
        (itDate, idx) =>
          `${idx > 0 ? ' – ' : ''}${
            itDate ? getDateString(itDate, format) : 'date tba'
          }`
      )}
    </Label>
  );
};

export default DateLabel;
