import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import { Icon } from '../Icon';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'small' | 'default' | 'large';
  color?: 'primary' | 'gray';
  detail?: boolean;
}

export interface StyledButtonProps {
  size?: 'small' | 'default' | 'large';
  color?: 'primary' | 'gray';
  detail?: boolean;
}

interface ButtonAttributes {
  small: string;
  default: string;
  large: string;
}

const buttonWidth: ButtonAttributes = {
  small: '116px',
  default: '160px',
  large: '327px',
};

const buttonHeight: ButtonAttributes = {
  small: '26px',
  default: '36px',
  large: '36px',
};

const buttonFontSize: ButtonAttributes = {
  small: '15px',
  default: '14px',
  large: '16px',
};

const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  justify-content: ${({ size }) =>
    size === 'small' ? 'flex-start' : 'center'};
  align-items: center;
  background-color: ${({ theme, color = 'primary' }) => theme.palette[color]};
  color: ${({ theme, color }) =>
    color === 'gray' ? theme.colors.white : theme.colors.black};
  font-size: ${({ size = 'default' }) => buttonFontSize[size]};
  font-weight: ${({ detail = false }) => (detail ? '500' : '700')};
  font-family: ${({ theme }) => theme.baseFontFamily};
  width: ${({ size = 'default' }) => buttonWidth[size]};
  height: ${({ size = 'default' }) => buttonHeight[size]};
  border-radius: 30px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme, color = 'primary' }) =>
      color === 'primary' ? theme.palette.primaryTint : theme.palette.medium};
  }

  .button-icon {
    margin-right: 5px;
  }
`;

const Button: React.FC<ButtonProps> = ({ children, detail, ...props }) => {
  return (
    <StyledButton {...props}>
      {detail && (
        <Icon
          className="button-icon"
          name="arrow"
          fill="black"
          rotate={180}></Icon>
      )}
      {children}
    </StyledButton>
  );
};

export default Button;
