import React, { useEffect, useState } from 'react';
import FixtureLog from '../components/fixture-log/fixture-log.page';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Company,
  DiscussionItem,
  Cargo,
  FreightRequestInterface,
} from '../models/freight-request.models';
import { GET_FIXTURE_LOG } from '../graphql/getFixtureLog.graphql';
import Loading from 'app/shared/Loading/Loading';
import ErrorContainer from 'app/shared/Error/ErrorContainer';
import { SEND_MESSAGE } from '../graphql/sendFixtureLogMessage.graphql';
import { SUBSCRIBE_SINGLE_FREIGHT_REQUEST } from '../graphql/subscribeFreightRequest.graphql';

interface FixtureLogProps {
  freightRequestId: string;
  closeFixtureLog: () => void;
}

interface Data {
  freightRequest: {
    company: Company;
    cargo: Cargo;
    discussion: DiscussionItem[];
  };
  freightRequestUpdated?: FreightRequestInterface;
}
interface Variables {
  freightRequestId: string;
  readFixtureLog?: boolean;
}

const FixtureLogContainer: React.FC<FixtureLogProps> = ({
  freightRequestId,
  closeFixtureLog,
}) => {
  const [discussionList, setDiscussionList] = useState<DiscussionItem[]>([]);
  const { loading, error, data, subscribeToMore } = useQuery<Data, Variables>(
    GET_FIXTURE_LOG,
    {
      variables: { freightRequestId, readFixtureLog: true },
    }
  );

  useEffect(() => {
    subscribeToMore({
      document: SUBSCRIBE_SINGLE_FREIGHT_REQUEST,
      variables: { freightRequestId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !subscriptionData.data.freightRequest)
          return prev;

        const {
          freightRequest: { discussion },
        } = subscriptionData.data;

        setDiscussionList(discussion);

        return {
          ...prev,
          discussion,
        };
      },
    });
  }, [subscribeToMore, freightRequestId]);

  useEffect(() => {
    if (data && data.freightRequest && data.freightRequest.discussion) {
      const { discussion } = data.freightRequest;
      setDiscussionList(discussion);
    }
  }, [data]);

  const [sendMessage] = useMutation(SEND_MESSAGE);

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return (
      <ErrorContainer
        title="Error getting log"
        message={JSON.stringify(error, undefined, 2)}
      />
    );
  }

  if (!data.freightRequest) {
    return (
      <ErrorContainer
        title="Got data but no FR"
        message={JSON.stringify(data, undefined, 2)}
      />
    );
  }

  const { company, cargo } = data.freightRequest;

  return (
    <FixtureLog
      companyName={company.companyName}
      cargo={cargo}
      sendMessage={(message: string) =>
        sendMessage({ variables: { freightRequestId, message } })
      }
      discussion={discussionList}
      closeAction={closeFixtureLog}
    />
  );
};

export default FixtureLogContainer;
