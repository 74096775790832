import {
  FreightRequestInterface,
  ShippingRoute,
  Ship,
  Status,
} from '../models/freight-request.models';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_SINGLE_FREIGHT_REQUEST, FREIGHT_OFFER_MUTATION } from '../graphql';
import { useState, useEffect } from 'react';
import { SUBSCRIBE_SINGLE_FREIGHT_REQUEST } from '../graphql/subscribeFreightRequest.graphql';
import { FREIGHT_REQUEST_STATUS_MUTATION } from '../graphql/updateFreightRequestStatus.graphql';

interface FreightRequestQueryData {
  freightRequest?: FreightRequestInterface;
}
interface FreightRequestQueryVariables {
  freightRequestId: string;
}

interface FreightRequestMutationVariables {
  freightRequestId: string;
  offers: Partial<ShippingRoute>[];
  ship?: Partial<Ship>;
}

interface FreightRequestStatusMutationVariables {
  freightRequestId: string;
  status: Status;
  statusReason: string;
  statusComment?: string;
}

interface FreightRequestMutationData {
  upsertFreightOffer: FreightRequestInterface;
}

type HookProps = (
  freightRequestId: string
) => {
  error: Error | null;
  freightRequest: FreightRequestInterface | null;
  loading: boolean;
  upsertOffer: (offers: Partial<ShippingRoute>[], ship?: Partial<Ship>) => void;
  updateFreightRequestStatus: (
    input: FreightRequestStatusMutationVariables
  ) => void;
};

const useFreightRequest: HookProps = (freightRequestId: string) => {
  /* STATE */
  const [
    freightRequest,
    setFreightRequest,
  ] = useState<FreightRequestInterface | null>(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  /* GRAPHQL */

  const {
    loading: queryLoading,
    error: queryError,
    data: freightRequestData,
    subscribeToMore,
    refetch: refetchFreightRequest,
  } = useQuery<FreightRequestQueryData, FreightRequestQueryVariables>(
    GET_SINGLE_FREIGHT_REQUEST,
    { variables: { freightRequestId } }
  );

  const [
    mutateFreightOffer,
    {
      data: offerResponseData,
      loading: offerResponseLoading,
      error: offerResponseError,
    },
  ] = useMutation<FreightRequestMutationData, FreightRequestMutationVariables>(
    FREIGHT_OFFER_MUTATION
  );

  const [mutateFreightRequestStatus] = useMutation<
    { updateFreightRequestStatus: boolean },
    FreightRequestStatusMutationVariables
  >(FREIGHT_REQUEST_STATUS_MUTATION);

  /* EFFECTS */

  useEffect(() => {
    subscribeToMore({
      document: SUBSCRIBE_SINGLE_FREIGHT_REQUEST,
      variables: { freightRequestId },
      updateQuery: (previousState, { subscriptionData }) => {
        if (!(subscriptionData && subscriptionData.data)) {
          return previousState;
        }

        const { freightRequest: subFreightRequest } = subscriptionData.data;

        return {
          ...previousState,
          ...subFreightRequest,
        };
      },
      onError: err => console.error(err),
    });
    refetchFreightRequest();
  }, [subscribeToMore, freightRequestId, refetchFreightRequest]);

  useEffect(() => {
    if (freightRequestData && freightRequestData.freightRequest) {
      const { freightRequest: dataFreightRequest } = freightRequestData;
      setFreightRequest(dataFreightRequest);
    }
    setLoading(queryLoading);
  }, [freightRequestData, queryLoading]);

  useEffect(() => {
    if (queryError) {
      setError(queryError);
    }
    setLoading(queryLoading);
  }, [queryError, queryLoading]);

  useEffect(() => {
    if (offerResponseError) {
      setError(offerResponseError);
    }
    setLoading(offerResponseLoading);
  }, [offerResponseLoading, offerResponseError]);

  useEffect(() => {
    if (offerResponseData) {
      const { upsertFreightOffer } = offerResponseData;
      setFreightRequest(upsertFreightOffer);
    }
  }, [offerResponseData, offerResponseLoading]);

  /* FUNCTIONS */
  const upsertOffer = (
    offers: Partial<ShippingRoute>[],
    ship?: Partial<Ship>
  ) => {
    mutateFreightOffer({
      variables: { freightRequestId, offers, ship },
    });
  };

  const updateFreightRequestStatus = (
    statusUpdate: FreightRequestStatusMutationVariables
  ) => {
    mutateFreightRequestStatus({
      variables: { ...statusUpdate },
    });
  };

  /* RETURNS */
  if (!freightRequest) {
    return {
      error,
      loading: queryLoading,
      freightRequest: null,
      upsertOffer,
      updateFreightRequestStatus,
    };
  }

  return {
    error,
    loading,
    freightRequest,
    upsertOffer,
    updateFreightRequestStatus,
  };
};

export { useFreightRequest };
