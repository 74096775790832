import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface TitleProps extends HTMLAttributes<HTMLLabelElement> {
  subtitle?: boolean;
  center?: boolean;
}

interface StyledTitleProps {
  subtitle?: boolean;
  center?: boolean;
}

const StyledTitle = styled.label<StyledTitleProps>`
  display: flex;
  flex-flow: row wrap;
  justify-content: ${({ center = false }) =>
    center ? 'center' : 'flex-start'};
  font-family: ${({ theme }) => theme.baseFontFamily};
  font-size: ${({ subtitle = false }) => (subtitle ? '14px' : '20px')};
  font-weight: ${({ subtitle = false }) => (subtitle ? '500' : '700')};
  margin: 5px 0 2px 0;
  text-transform: ${({ subtitle = false }) =>
    subtitle ? 'uppercase' : 'none'};
  color: ${({ theme, subtitle = false }) =>
    subtitle ? theme.palette.silver : theme.palette.white};
`;

const Title: React.FC<TitleProps> = ({ children, ...props }) => {
  return <StyledTitle {...props}>{children}</StyledTitle>;
};

export default Title;
