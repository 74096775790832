import React from 'react';
import { Card, CardContent, Document, Title } from 'fx-ui';
import { Document as DocumentInterface } from 'app/freight-request/models/freight-request.models';

interface FreightRequestDetailsDocumentsProps {
  documents: DocumentInterface[];
}

const FreightRequestDetailsDocuments: React.FC<
  FreightRequestDetailsDocumentsProps
> = ({ documents = [] }) => {
  const { length: amountDocuments } = documents;

  if (!(amountDocuments > 0)) {
    return null;
  }

  return (
    <Card>
      <CardContent>
        <Title subtitle>Documents ({amountDocuments})</Title>
        {documents.map(({ documentName, documentUrl }) => (
          <Document
            documentName={documentName}
            documentUrl={documentUrl}
            key={`document-${documentName}`}
          />
        ))}
      </CardContent>
    </Card>
  );
};

export default FreightRequestDetailsDocuments;
