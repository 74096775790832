import gql from 'graphql-tag';

export const GET_SINGLE_FREIGHT_REQUEST = gql`
  query($freightRequestId: ID!) {
    freightRequest: getFreightRequest(id: $freightRequestId) {
      freightRequestId
      company {
        companyName
      }
      details {
        layDate
        cancelling
        offerWithin
        offerValidTo
        offerSubsDue
      }
      cargo {
        cargoName
        quantity {
          unit
          amount
          tolerance
        }
      }
      routes {
        routeId
        addId
        loadLocations {
          locationName
          locationCode
        }
        dischargeLocations {
          locationName
          locationCode
        }
        loadComment
        dischargeComment
        offer {
          offerAmount
          offerUnit
          offerComment
        }
      }
      ship {
        id
        name
        laytimeHours
        overage
        demurrageRate
        comments
      }
      requirements {
        subject
        type
        details {
          reference
          description
        }
      }
      documents {
        documentName
        documentUrl
      }
      unreadMessages
      subTerms {
        description
        amendments {
          reference
          description
        }
      }
      conditions
      status
      modified
      created
    }
  }
`;
