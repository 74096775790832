import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { getIconDefinition } from './IconDefinitions';

export interface IconProps extends HTMLAttributes<HTMLDivElement> {
  name: string;
  fill?: string;
  width?: number | string;
  height?: number | string;
  scale?: number | string;
  rotate?: number | string;
  badge?: number;
}

interface StyledIconProps {
  fill?: string;
  width?: number | string;
  height?: number | string;
  scale?: number | string;
  rotate?: number | string;
}

const StyledIconContainer = styled.div`
  position: relative;

  .icon-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -8px;
    left: -8px;
    background: ${({ theme }) => theme.palette.danger};
    width: 17px;
    height: 17px;
    border-radius: 50%;
    z-index: 10;

    .icon-badge-label {
      color: ${({ theme }) => theme.colors.codGray};
      font-family: ${({ theme }) => theme.baseFontFamily};
      font-size: 11px;
      font-weight: 900;
      letter-spacing: -1px;
      margin-left: -1px;
    }
  }
`;

const StyledIcon = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
  xmlSpace: 'preserve',
})<StyledIconProps>`
  transform: ${({ scale = 1, rotate = 0 }) =>
    `scale(${scale}) rotate(${rotate}deg)`};
  width: ${({ width = '24px' }) => width};
  max-height: ${({ height = '32px' }) => height};
  fill: ${({ theme, fill }) =>
    fill ? theme.palette[fill] : theme.iconFillColor};
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
`;

const Icon: React.FC<IconProps> = ({
  name,
  fill,
  scale,
  rotate,
  badge = 0,
  ...props
}) => {
  const { icon, viewBox } = getIconDefinition(name);

  return (
    <StyledIconContainer {...props}>
      {badge > 0 && (
        <div className="icon-badge">
          <label className="icon-badge-label">{badge}</label>
        </div>
      )}
      <StyledIcon viewBox={viewBox} fill={fill} scale={scale} rotate={rotate}>
        {icon}
      </StyledIcon>
    </StyledIconContainer>
  );
};

export default Icon;
