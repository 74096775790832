import React from 'react';
import { Container } from '../Container';
import { Label } from '../Label';
import styled from 'styled-components';
import { ShippingRoute as RouteProps } from 'app/freight-request/models/freight-request.models';

const StyledLocationLabel = styled(Label)`
  margin: 0 0 4px 15px;
  position: relative;
  &::before {
    content: ' ';
    width: 7px;
    height: 7px;
    background-color: white;
    position: absolute;
    left: -15px;
    border-radius: 100%;
    transform: translateY(4px);
  }
  &:first-child::after {
    content: ' ';
    background-color: white;
    width: 1px;
    position: absolute;
    left: -12px;
    top: 10px;
    height: 100%;
  }
`;

const Route: React.FC<RouteProps> = ({
  routeId = '',
  loadLocations = [],
  dischargeLocations = [],
  offer,
}) => (
  <Container>
    <Container column>
      {[
        { key: 'load', locations: loadLocations },
        { key: 'discharge', locations: dischargeLocations },
      ].map(({ key, locations }) => (
        <StyledLocationLabel
          className="route-label"
          key={`${routeId}-${key}-locations`}
          small>
          {locations.map(
            ({ locationName }, idx) => `${idx > 0 ? ' – ' : ''}${locationName}`
          )}
        </StyledLocationLabel>
      ))}
    </Container>
  </Container>
);

export default Route;
